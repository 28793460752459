<template>
  <div>
    <section id="section1" :style="{ height: $calcHeight(950) }">
      <div data-aos="zoom-in">
        <div class="title">{{ $t('title[1]') }}</div>
        <div class="sub-title">{{ $t('title.sub[1]') }}</div>
      </div>
      <div id="buttons">
        <div>{{ $t('title[2]') }}</div>
        <div>{{ $t('title[3]') }}</div>
      </div>
    </section>
    <section id="section2" :style="{ height: $calcHeight(950) }">
      <div class="title" data-aos="zoom-in">{{ $t('title[4]') }}</div>
      <div class="tip" @click="handleClick('commercial')">{{ $t('tip[1]') }}</div>
    </section>
    <section id="section3" :style="{ height: $calcHeight(950) }">
      <div class="title" data-aos="zoom-in">{{ $t('title[5]') }}</div>
      <div class="tip" @click="handleClick('household')">{{ $t('tip[1]') }}</div>
    </section>
  </div>
</template>

<script>
  export default {
    
    name: "Home",
    
    data() {
      return {
        
      }
    },
    
    methods: {
      handleClick(type) {
        this.$router.push('/solution/' + type);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #section1 {
    width: 100%;
    background-image: url('../assets/images/home/section-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 12% 0 4% 0;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    .title {
      font-weight: bold;
      color: #FFFFFF;
    }
    
    .sub-title {
      margin-top: 2rem;
    }
    
    #buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 15%;
      color: #FFFFFF;
    }
  }
  
  #section2 {
    width: 100%;
    background-image: url('../assets/images/home/section-2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 6%;
    box-sizing: border-box;
    text-align: center;
    
    .title {
      font-weight: bold;
      color: #FFFFFF;
    }
    
    .tip {
      margin-top: 2rem;
      cursor: pointer;
    }
  }
  
  #section3 {
    width: 100%;
    background-image: url('../assets/images/home/section-3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 6%;
    box-sizing: border-box;
    text-align: center;
    
    .title {
      font-weight: bold;
      color: #FFFFFF;
    }
    
    .tip {
      margin-top: 2rem;
      cursor: pointer;
    }
  }
  
  @media screen and (min-width: 1401px) {
    section {
      .title {
        font-size: 4.2rem;
      }
      
      .sub-title {
        font-size: 2.1rem;
      }
      
      .tip {
        font-size: 2.1rem;
      }
      
      #buttons {
        font-size: 3rem;
      }
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    section {
      .title {
        font-size: 3.2rem;
      }
      
      .sub-title {
        font-size: 1.6rem;
      }
      
      .tip {
        font-size: 1.6rem;
      }
      
      #buttons {
        font-size: 2.4rem;
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    section {
      .title {
        font-size: 4.2rem;
      }
      
      .sub-title {
        font-size: 2.1rem;
      }
      
      .tip {
        font-size: 2.1rem;
      }
      
      #buttons {
        font-size: 3rem;
      }
    }
  }
</style>