<template>
  <div id="page">
    <div id="title">{{ $t('title[13]') }}</div>
    <div id="name">{{ $t('title.sub[30]') }}</div>
    <div id="devices">
      <div></div>
      <img src="@/assets/images/product/household/single/technical1.png" alt="technical1" width="20%" height="auto">
      <img src="@/assets/images/product/household/single/technical2.png" alt="technical2" width="20%" height="auto">
    </div>
    <div id="table">
      <!-- System Parameters -->
      <table-tr :data="[$t('table.th[2]')]" />
      <table-tr :data="[$t('table.td[92]'), '3000-6000W']" />
      <table-tr :data="[$t('table.td[93]'), '1', '2']" />
      <table-tr :data="[$t('table.td[94]') + '[1]', '5.12kWh', '10.24kWh']" />
      <table-tr :data="[$t('table.td[95]') + '[2]', '4.75kWh', '9.5kWh']" />
      <table-tr :data="[$t('table.td[53]'), 'IP65']" />
      <table-tr :data="[$t('table.td[96]') + '[3]', '-10℃~+50℃']" />
      <table-tr :data="[$t('table.td[97]'), '5%~95%']" />
      <table-tr :data="[$t('table.td[98]') + '[4]', '4000m']" />
      <table-tr :data="[$t('table.td[55]'), '74.5kg', '125.5kg']" />
      <table-tr :data="[$t('table.td[82]'), '708*890*170mm', '708*1310*170mm']" />
      <table-tr :data="[$t('table.td[99]'), 'LCD & APP+Bluetooth']" />
      <table-tr :data="[$t('table.td[100]'), $t('table.td[101]')]" />
      <table-tr :data="[$t('table.td[102]'), '[ESI 3~6K~S1 Inverter Module] + N * [BTS 5K Battery Module]']" />
      <!-- Inverter Module -->
      <table-tr :data="[$t('table.th[3]')]" />
      <table-tr :data="[$t('table.td[103]'), 'ESI 3K-S1', 'ESI 3.68K-S1']" />
      <table-tr :data="[$t('table.td[104]'), '400V']" />
      <table-tr :data="[$t('table.td[105]'), '20A']" />
      <table-tr :data="[$t('table.td[106]'), '4500Wp', '5400Wp']" />
      <table-tr :data="[$t('table.td[107]'), '550V']" />
      <table-tr :data="[$t('table.td[108]'), '360V']" />
      <table-tr :data="[$t('table.td[109]'), '85~520V']" />
      <table-tr :data="[$t('table.td[110]'), '2']" />
      <table-tr :data="[$t('table.td[111]'), '16A/16A']" />
      <table-tr :data="[$t('table.td[112]'), '22.5A/22.5A']" />
      <table-tr :data="[$t('table.td[8]'), 'L/N/PE, 230V, 50Hz/60Hz']" />
      <table-tr :data="[$t('table.td[113]'), $t('table.td[114]')]" />
      <table-tr :data="[$t('table.td[115]'), '3000W', '3680W']" />
      <table-tr :data="[$t('table.td[116]'), '3300VA', '3680VA']" />
      <table-tr :data="[$t('table.td[117]'), '220/230/240V,50/60Hz']" />
      <table-tr :data="[$t('table.td[118]'), '3300W', '3680W']" />
      <table-tr :data="[$t('table.td[119]'), '3300VA', '3680VA']" />
      <table-tr :data="[$t('table.td[120]') + '[5]', '4500VA, 10S', '5520VA, 10S']" />
      <table-tr :data="[$t('table.td[121]'), '<10ms']" />
      <table-tr :data="[$t('table.td[122]'), '97.7%', '97.7%']" />
      <table-tr :data="[$t('table.td[123]'), '97%', '97%']" />
      <table-tr :data="[$t('table.td[124]'), $t('table.td[125]')]" />
      <table-tr :data="[$t('table.td[82]'), '708*410*170mm']" />
      <table-tr :data="[$t('table.td[55]'), '22.5kg']" />
      <!-- Battery Module -->
      <table-tr :data="[$t('table.th[4]')]" />
      <table-tr :data="[$t('table.td[126]'), 'BTS 5K']" />
      <table-tr :data="[$t('table.td[127]'), 'LFP[6]']" />
      <table-tr :data="[$t('table.td[128]'), '5.12kWh']" />
      <table-tr :data="[$t('table.td[129]'), '2500W']" />
      <table-tr :data="[$t('table.td[124]'), $t('table.td[130]')]" />
      <table-tr :data="[$t('table.td[82]'), '708*420*170mm']" />
      <table-tr :data="[$t('table.td[55]'), '50kg']" />
      <!-- Standard -->
      <table-tr :data="[$t('table.th[5]')]" text="EN 61000-6-2, EN 61000-6-3, EN 61000-3-2, EN 61000-3-3, EN 61000-3-11, EN 61000-3-12, IEC 62109-1/2, IEC 62040-1, IEC 62116, IEC 61727, IEC 61683, IEC 60068(1,2,14,30), UN38.3, IEC62619, VDE-AR-N 4105, VDE V 0126-1-1, AS/NZS 4777, CEI 0-21, C10/11, G98/G99, TR321, TR322, EN 50438/EN50549, UTE C15-712-1, NRS 097-2-1, UNE 206 007-1" />
      <table-tr :data="['【1】' + $t('text[43]'), '【2】' + $t('text[44]'), '【3】' + $t('text[45]'), '【4】' + $t('text[46]'), '【5】' + $t('text[47]'), '【6】' + $t('text[48]'), '*' + $t('text[49]')]" />
    </div>
  </div>
</template>

<script>
  import TableTr from '@/components/TableTr.vue';
  
  export default {
    
    name: 'SingleTechnical',
    
    components: {
      'table-tr': TableTr
    },
    
    data() {
      return {
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  #page {
    margin: 0 auto;
    
    #title {
      font-size: 4rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding: 6rem 0;
    }
    
    #name {
      font-size: 3rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    
    #devices {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
    }
    
    #table {
      width: 100%;
      color: #333333;
      margin: 40px 0 85px 0;
    }
  }
  
  @media screen and (min-width: 1401px) {
    #page {
      width: 70%;
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    #page {
      width: 80%;
    }
  }
  
  @media screen and (max-width: 700px) {
    #page {
      width: 90%;
    }
  }
</style>