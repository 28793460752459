<template>
  <div>
    <!-- device0 -->
    <div id="device0" :style="{ height: $calcHeight(760) }">
      <div id="container" data-aos="fade-up">
        <div id="title">{{ $t('title.sub[6]') }}</div>
        <div id="content">
          <div class="item">
            <img src="@/assets/icons/product/household/icon-1.png" alt="icon1" class="img">
            <div class="text">{{ $t('text[29]') }}</div>
          </div>
          <div class="item">
            <img src="@/assets/icons/product/household/icon-2.png" alt="icon2" class="img">
            <div class="text">{{ $t('text[30]') }}</div>
          </div>
          <div class="item">
            <img src="@/assets/icons/product/household/icon-3.png" alt="icon3" class="img">
            <div class="text">{{ $t('text[31]') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- device1 -->
    <div id="device1" data-aos="fade-up">
      <img src="@/assets/images/product/household/three/device1.png" alt="device1" width="100%" height="auto">
      <div class="text1">{{ $t('text[32]') }}</div>
      <div class="text2">{{ $t('text[33]') }}</div>
    </div>
    <!-- device2 -->
    <div id="device2" data-aos="fade-up">
      <div>
        <div class="text1">{{ $t('text[34]') }}</div>
        <div class="text2">{{ $t('text[35]') }}</div>
      </div>
      <img src="@/assets/images/product/household/three/device2.png" alt="device2" width="45%" height="auto">
    </div>
    <!-- device3 -->
    <div id="device3" data-aos="fade-up">
      <img src="@/assets/images/product/household/three/device3.png" alt="device3" width="45%" height="auto">
      <div>
        <div class="text1">{{ $t('text[36]') }}</div>
        <div class="text2">{{ $t('text[37]') }}</div>
      </div>
    </div>
    <!-- device4 -->
    <div id="device4" data-aos="fade-up">
      <div>
        <div class="text1">{{ $t('text[38]') }}</div>
        <div class="text2">{{ $t('text[39]') }}</div>
      </div>
      <img src="@/assets/images/product/household/three/device4.png" alt="device4" width="45%" height="auto">
    </div>
    <!-- device5 -->
    <div id="device5" data-aos="fade-up">
      <img src="@/assets/images/product/household/three/device5.png" alt="device5" width="45%" height="auto">
      <div>
        <div class="text1">{{ $t('text[40]') }}</div>
        <div class="text2">{{ $t('text[41]') }}<br />{{ $t('text[42]') }}</div>
      </div>
    </div>
    <!-- more details -->
    <div id="more-details">
      <div id="title" data-aos="zoom-in">{{ $t('title[12]') }}</div>
      <carousel :domain="domain" />
    </div>
  </div>
</template>

<script>
  import Carousel from '@/components/Carousel.vue';
  
  export default {
    
    name: 'ThreeOverview',
    
    components: {
      'carousel': Carousel
    },
    
    data() {
      return {
        domain: 'three'
      }
    }
  }
</script>

<style lang="scss" scoped>
  $padding-x: 18%;
  
  .text1 {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  
  .text2 {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  
  #device0 {
    width: 100%;
    background-size: 100% 100%;
    background-image: url("@/assets/images/product/household/three/device0.png");
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    #container {
      width: 35%;
      margin-right: 13%;
      text-align: center;
      
      #title {
        font-size: 4rem;
        font-family: DIN;
        font-weight: bold;
        white-space: nowrap;
        color: #FFFFFF;
        margin-bottom: 80px;
        user-select: none;
      }
      
      #content {
        display: flex;
        justify-content: space-between;
        
        .item {
          width: 20rem;
          
          .img {
            width: 8.5rem;
            height: 7.5rem;
          }
          
          .text {
            font-size: 1.8rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            user-select: none;
          }
        }
      }
    }
  }
  
  #device1 {
    text-align: center;
    padding: 0 $padding-x;
    padding-top: 6rem;
    background-color: #F9F9F9;
    
    .text1 {
      margin-top: 6rem;
    }
    
    .text2 {
      padding: 2.5rem 0;
    }
  }
  
  #device2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8rem;
    background-color: #FFFFFF;
    padding: 10rem $padding-x;
    
    .text1 {
      margin-bottom: 3rem;
    }
  }
  
  #device3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F9F9F9;
    padding: 10rem $padding-x;
    gap: 8rem;
    
    .text1 {
      margin-bottom: 3rem;
      text-align: right;
    }
    
    .text2 {
      text-align: right;
    }
  }
  
  #device4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8rem;
    background-color: #FFFFFF;
    padding: 10rem $padding-x;
    
    .text1 {
      margin-bottom: 3rem;
    }
  }
  
  #device5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F9F9F9;
    padding: 10rem $padding-x;
    gap: 8rem;
    
    .text1 {
      text-align: right;
      margin-bottom: 3rem;
    }
    
    .text2 {
      text-align: right;
    }
  }
  
  #more-details {
    background-color: #FFFFFF;
    text-align: center;
    padding: 10rem 0;
    
    #title {
      font-size: 4rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0A0205;
      margin-bottom: 10rem;
    }
  }
  
  @media screen and (min-width: 1401px) {
    .text {
      font-size: 1.6rem;
      line-height: 3rem;
    }
    
    .text1 {
      font-size: 2.4rem;
    }
    
    .text2 {
      font-size: 1.6rem;
    }
    
    #device0 {
      #container {
        #title {
          margin-bottom: 8rem;
        }
        
        #content {
          .item {
            .text {
              margin-top: 3rem;
            }
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    .text {
      font-size: 1.6rem;
      line-height: 3rem;
    }
    
    .text1 {
      font-size: 2.4rem;
    }
    
    .text2 {
      font-size: 1.6rem;
    }
    
    #device0 {
      #container {
        #title {
          margin-bottom: 6rem;
          font-size: 2.4rem;
        }
        
        #content {
          .item {
            .text {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .text {
      font-size: 3.2rem;
      line-height: 4rem;
    }
    
    .text1 {
      font-size: 3.2rem;
    }
    
    .text2 {
      font-size: 2.4rem;
    }
    
    #device0 {
      #container {
        #title {
          margin-bottom: 4rem;
        }
        
        #content {
          .item {
            .text {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
</style>