<template>
  <div>
    <carousel-3d ref="carousel" autoplay controlsVisible :autoplayTimeout="3000" :space="360" :animationSpeed="1000" :width="400" :height="230" @before-slide-change="onSlideChange">
      <slide v-for="(item, index) in 5" :index="index" :key="index">
        <img :src="require('@/assets/images/product/household/' + domain + '/banner' + item + '.png')" :alt="'banner' + item" style="mix-blend-mode: multiply;" @click="openModal(index)" />
      </slide>
    </carousel-3d>
    <div id="indicator">
      <div :class="['item', { 'is-active': currentIndex === index }]" v-for="(item, index) in 5" :key="index" @click="goSlideIndex(index)"></div>
    </div>
    <div id="modal" v-if="modalOpen">
      <div style="width: 80%; height: 80%; padding: 50px; background-color: #FFFFFF;">
        <div id="close-icon" @click="closeModal"><i class="el-icon-circle-close"></i></div>
        <el-carousel :autoplay="false" :initial-index="currentIndex" arrow="always" trigger="click" height="800px" style="margin-top: -30px;">
          <el-carousel-item v-for="item in 5" :key="item">
            <img :src="require('@/assets/images/product/household/' + domain + '/banner' + item + '.png')" :alt="'banner' + item" width="85%" height="auto" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
  import { Carousel3d, Slide } from 'vue-carousel-3d'
  
  export default {
    
    name: 'Carousel',
    
    components: { Carousel3d, Slide },
    
    props: {
      domain: { type: String, default: 'single' }
    },
    
    data() {
      return {
        currentIndex: 0,
        modalOpen: false
      }
    },
    
    methods: {
      goSlideIndex(index) {
        this.$refs.carousel.goFar(index);
      },
      onSlideChange(index) {
        this.currentIndex = index;
      },
      openModal(index) {
        if(this.$isBigScreen) {
          this.currentIndex = index;
          this.modalOpen = true;
        }
      },
      closeModal() {
        this.modalOpen = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .carousel-3d-slide {
    border: none;
    cursor: pointer;
    background-color: #797979;
    
    &:hover {
      background-color: #F3F3F3;
    }
  }
  
  .current {
    background-color: #F3F3F3;
  }
  
  #indicator {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 5rem;
    
    .item {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    
    .is-active {
      background-color: #333333;
    }
  }
  
  #modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    
    #close-icon {
      position: relative;
      top: -45px;
      right: -45px;
      text-align: right;
      
      .el-icon-circle-close {
        font-size: 30px;
        font-weight: bold;
        cursor: pointer;
        color: #333333;
      }
    }
    
    ::v-deep(.el-carousel__button) {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-top: 50px;
      background-color: #303030;
    }
  }
</style>