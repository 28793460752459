<template>
  <div>
    <!-- device0 -->
    <div id="device0" :style="{ height: $calcHeight(760) }">
      <div id="container" data-aos="fade-up">
        <div id="title">{{ $t('title.sub[5]') }}</div>
        <div id="content">
          <div class="item">
            <img src="@/assets/icons/product/household/icon-1.png" alt="icon1" class="img">
            <div class="text">{{ $t('text[22]') }}</div>
          </div>
          <div class="item">
            <img src="@/assets/icons/product/household/icon-2.png" alt="icon2" class="img">
            <div class="text">{{ $t('text[23]') }}</div>
          </div>
          <div class="item">
            <img src="@/assets/icons/product/household/icon-3.png" alt="icon3" class="img">
            <div class="text">{{ $t('text[24]') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- device1 -->
    <div id="device1" data-aos="fade-up">
      <img src="@/assets/images/product/household/single/device1.png" alt="device1" width="45%" height="auto">
      <div class="text">{{ $t('text[25]') }}</div>
    </div>
    <!-- device2 -->
    <div id="device2" data-aos="fade-up">
      <div class="text">{{ $t('text[26]') }}</div>
      <img src="@/assets/images/product/household/single/device2.png" alt="device2" width="27.6%" height="auto">
    </div>
    <!-- device3 -->
    <div id="device3-device4" data-aos="fade-up">
      <div class="item">
        <img src="@/assets/images/product/household/single/device3.png" alt="device3" width="auto" height="80%">
        <div class="text">{{ $t('text[27]') }}</div>
      </div>
      <div class="item">
        <img src="@/assets/images/product/household/single/device4.png" alt="device4" width="auto" height="80%">
        <div class="text">{{ $t('text[28]') }}</div>
      </div>
    </div>
    <!-- more details -->
    <div id="more-details">
      <div id="title" data-aos="zoom-in">{{ $t('title[12]') }}</div>
      <carousel :domain="domain" />
    </div>
  </div>
</template>

<script>
  import Carousel from '@/components/Carousel.vue';
  
  export default {
    
    name: 'SingleOverview',
    
    components: {
      'carousel': Carousel
    },
    
    data() {
      return {
        domain: 'single'
      }
    }
  }
</script>

<style lang="scss" scoped>
  #device0 {
    width: 100%;
    background-size: 100% 100%;
    background-image: url("@/assets/images/product/household/single/device0.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    
    #container {
      width: 35%;
      margin-left: 13%;
      text-align: center;
      
      #title {
        font-size: 4rem;
        font-family: DIN;
        font-weight: bold;
        white-space: nowrap;
        color: #FFFFFF;
        user-select: none;
      }
      
      #content {
        display: flex;
        justify-content: space-between;
        
        .item {
          width: 15rem;
          
          .img {
            width: 8.5rem;
            height: 7.5rem;
          }
          
          .text {
            font-size: 1.8rem;
            line-height: 3rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            user-select: none;
          }
        }
      }
    }
  }
  
  #device1 {
    text-align: center;
    padding-top: 6rem;
    background-color: #F9F9F9;
    
    .text {
      padding: 6rem 0;
    }
  }
  
  #device2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding: 10rem 0;
    
    .text {
      width: 30%;
      margin-right: 8rem;
    }
  }
  
  #device3-device4 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 4rem;
    background-color: #F9F9F9;
    padding: 10rem 0;
    
    .item {
      width: 40%;
      height: 70rem;
      text-align: center;
      
      .text {
        background-color: #FFFFFF;
        margin-top: 5rem;
        padding: 2rem;
      }
    }
  }
  
  #more-details {
    background-color: #FFFFFF;
    text-align: center;
    padding: 10rem 0;
    
    #title {
      font-size: 4rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0A0205;
      margin-bottom: 10rem;
    }
  }
  
  @media screen and (min-width: 1401px) {
    .text {
      font-size: 1.6rem;
      line-height: 3rem;
    }
    
    #device0 {
      #container {
        #title {
          margin-bottom: 8rem;
        }
        
        #content {
          .item {
            .text {
              margin-top: 3rem;
            }
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    .text {
      font-size: 1.6rem;
      line-height: 3rem;
    }
    
    #device0 {
      #container {
        #title {
          margin-bottom: 6rem;
          font-size: 2.4rem;
        }
        
        #content {
          .item {
            .text {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .text {
      font-size: 3.2rem;
      line-height: 4rem;
    }
    
    #device0 {
      #container {
        #title {
          margin-bottom: 4rem;
        }
        
        #content {
          .item {
            .text {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
</style>