import { render, staticRenderFns } from "./CollapseCard.vue?vue&type=template&id=3f6ec736&scoped=true&"
import script from "./CollapseCard.vue?vue&type=script&lang=js&"
export * from "./CollapseCard.vue?vue&type=script&lang=js&"
import style0 from "./CollapseCard.vue?vue&type=style&index=0&id=3f6ec736&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f6ec736",
  null
  
)

export default component.exports