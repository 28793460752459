export default {
  menu: {
    home: 'Home',
    solution: 'Solution',
    household: 'Household',
    commercial: 'Commercial',
    commercialCases: 'Commercial cases',
    product: 'Product',
    householdProduct: 'Household product',
    commercialProduct: 'Commercial product',
    about: 'About Us',
    single: 'Single-phase fusioncube',
    three: 'Three-phase fusioncube',
    overview: 'Overview',
    specification: 'Technical Specifications'
  },
  
  info: {
    record: 'Su ICP No. 13007749-8',
    copyright: 'Shanghai Noether Energy Technology Co., Ltd. ALL RIGHTS RESERVED.'
  },
  
  title: {
    '1': 'Empower the future and store unlimited wisdom',
    '2': 'One-stop software and hardware system solution',
    '3': 'Ai-driven EMS energy management system',
    '4': 'Industrial and commercial energy storage solutions',
    '5': 'Energy storage solutions for households',
    '6': 'Scheme characteristics',
    '7': 'Related products',
    '8': 'EMS Platform (Energy Management System)',
    '9': 'Financial service',
    '10': 'Product advantage',
    '11': 'Technical specification',
    '12': 'More Details',
    '13': 'Datasheet',
    sub: {
      '1': 'Efficient use of every kilowatt-hour with innovative technology',
      '2': 'Flexible configuration',
      '3': 'Intelligent and efficient',
      '4': 'Security and stability',
      '5': 'FunCube Single-phase',
      '6': 'FunCube Three-phase',
      '7': 'Efficient and flexible',
      '8': 'Lower LCOS',
      '9': 'Extreme security',
      '10': 'Intelligent scheduling',
      '11': 'Ac liquid cooled energy storage cabinet',
      '12': 'Liquid cooled container type energy storage cabinet',
      '13': 'Integrated industrial and commercial energy storage system',
      '14': 'Observable (visualization)',
      '15': 'Measurable (system and algorithm prediction)',
      '16': 'Controllable (digital, electronic control)',
      '17': 'Power prediction system',
      '18': 'New energy equipment safety management system',
      '19': 'Equipment investment:',
      '20': 'Equipment Rental:',
      '21': 'Asset sales:',
      '22': 'Income distribution:',
      '23': 'High security',
      '24': 'High energy efficiency',
      '25': 'long-life',
      '26': 'Parallel off-network',
      '27': 'Easy configuration',
      '28': 'High Gains',
      '29': 'Intelligent management',
      '30': 'Appearance',
      '31': 'Smart electricity, safety and energy saving',
      '32': 'Commercial energy storage, wisdom sharing, safety and efficiency'
    }
  },
  
  tip: {
    '1': 'Further understanding of details >'
  },
  
  text: {
    '1': 'Founded in February 2023, Norsi New (Shanghai) Energy Technology Co., LTD is a company founded by a professional team with background in energy storage industry and Internet industry. The team members have rich experience in the development and operation and maintenance of energy storage industry and energy Internet aggregation platform. Among them, two key personnel have successfully founded VPP virtual power plant company overseas, and was acquired by Shell and Engie. The company develops an industry-leading AI-driven EMS energy management system, with hardware and software system solutions related to energy storage as its core business, and its products and services cover a variety of application scenarios such as household and industrial and commercial energy storage.',
    '2': 'Home energy storage through the combination of distributed photovoltaic and energy storage, in the residents homes, through solar panels and other new energy power generation equipment for home power supply, while the power management, excess electricity storage, and supply to the grid. Not only can be used as an emergency power supply, but also can save power expenses for families, for general household appliances, computers, lighting, communication equipment and so on.',
    '3': 'System power 3~120kW',
    '4': 'Support multi-machine parallel battery capacity 5kWh~240kWh',
    '5': 'Power electronics are deeply coupled, and the available capacity of the battery life cycle is increased by 6%',
    '6': 'APP and cloud data interconnection, anytime and anywhere operation and maintenance',
    '7': 'Support anti-reverse flow, can provide a variety of energy storage mode',
    '8': 'Industrial and commercial energy storage systems use lithium batteries as energy storage devices, through the local and remote EMS management system, to complete the power grid, battery, load between the power supply and power demand balance and optimization, and can easily access photovoltaic and other new energy equipment, in the peak and valley power consumption, distribution network capacity, electricity safety, improve the quality of electricity and other aspects of the application value. At the same time as the core node to achieve smart grid access. Widely used in commercial complexes, factories, hospitals, data centers and other scenarios.',
    '9': 'Modular design, flexible expansion, support automatic and off-grid switching',
    '10': 'Master-slave architecture design, high area energy density',
    '11': 'Energy storage cabinet Pre-connect cables to the battery cabinet',
    '12': 'Long cycle life, low electricity cost',
    '13': 'Water and electricity separation, 3+2 safety system',
    '14': 'Integrated EMS functions: counter-current prevention, peak cutting and valley filling, demand management, etc',
    '15': 'Including the procurement and installation of key equipment such as batteries, energy storage converters, and energy management systems',
    '16': 'The rental service of energy storage equipment usually includes the installation, maintenance and update of the equipment, and the user only needs to pay the rental fee according to the need. In addition, customized rental programs may be available to meet the needs of different users',
    '17': 'Users can buy energy storage equipment at any time under the conditions of financial strength',
    '18': 'Through peak and valley arbitrage, nodes save money',
    '19': 'Applicable to users whose annual electricity bill is less than 10 million yuan',
    '20': 'Applicable to users whose annual electricity bill is at least 10 million yuan',
    '21': 'Modular design, flexible capacity expansion supports automatic and off-network switching, applicable to multiple scenarios',
    '22': 'Single-phase',
    '23': 'Number of MPP trackers: 2',
    '24': 'Maximum PV input current 16A',
    '25': 'Built-in battery pack balance management unit, increases battery capacity',
    '26': 'Max. photovaltaic current input: 16A, suitable for high-current/bifacial modules',
    '27': 'Stacked and integrated design, convenient installationand simple maintenance',
    '28': 'The battery side is completely electrically isolated, making the system safer',
    '29': 'Three-phase',
    '30': 'Dual MPPT',
    '31': 'Maximum charging and discharging current: 50A',
    '32': 'Scalable System Solutions',
    '33': 'With up to 10 parallel systems',
    '34': 'Flexible Configuration',
    '35': 'With up to 2 MPPTs, providing a more flexible configuration',
    '36': 'High Charging Efficiency',
    '37': 'Compatible with high voltage battery, it could support higt charging and discharging efficiency. Battery adaptability is also strong due to wide voltage range',
    '38': 'Fully Digitalized Operation',
    '39': 'Enabling higher control accuracy',
    '40': 'Modular and Integrated Design',
    '41': 'Easy transportation and installation',
    '42': 'Scalable for battery capacity expansion',
    '43': 'Test condition:0.2C charging/discharging at 25℃, 100%DOD.',
    '44': 'Based on the battery cell.',
    '45': 'Please refer to the temperature derating curve.',
    '46': 'If the altitude is >2000 m, derating is required, Please refer to the derating curve.',
    '47': 'In a system with sufficient PV and battery power.',
    '48': 'Please refer to document “SOFAR inverter Model compatible battery list”.',
    '49': 'All specifications are subject to change without notice.',
    '50': 'Battery-AC maximum effciency of battery charge and discharge',
    '51': 'Standby loss at reted input voltage',
    '52': 'No need to lay cables on site'
  },
  
  table: {
    th: {
      '1': 'Product model',
      '2': 'System Parameters',
      '3': 'Inverter Module',
      '4': 'Battery Module',
      '5': 'Standard',
      '6': 'DC input (PV)',
      '7': 'AC output (on-grid)',
      '8': 'Battery parameters',
      '9': 'AC output (off-grid)',
      '10': 'Efficency',
      '11': 'Protection',
      '12': 'General parameters'
    },
    td: {
      '1': 'DC side parameters',
      '2': 'Cell type',
      '3': 'System battery voltage range',
      '4': 'Dc protection',
      '5': 'AC side parameters',
      '6': 'Rated AC power',
      '7': 'Ac maximum power',
      '8': 'Rated grid voltage',
      '9': 'Rated network frequency',
      '10': 'Grid type',
      '11': 'Low pressure side',
      '12': 'Battery Cabinet Size (W*D*H)',
      '13': 'Battery tank weight',
      '14': 'Basic parameter',
      '15': 'Maximum system efficiency',
      '16': '≥90%(rated condition)',
      '17': 'Charge and discharge ratio',
      '18': 'Cooling mode',
      '19': 'Liquid cooling system',
      '20': 'PCS protection class',
      '21': 'System protection class',
      '22': 'Cycle number',
      '23': 'Operating temperature range',
      '24': 'Charging: 0℃~50℃, discharge: -20℃~55℃',
      '25': 'Refrigerating capacity',
      '26': 'Maximum working altitude',
      '27': '4000m(＞4000m custom)',
      '28': 'noise',
      '29': 'Communication interface',
      '30': 'Ethernet、RS485',
      '31': 'Charge and discharge switching time',
      '32': 'Fire protection system',
      '33': 'Audible and visual alarm/aerosol/perfluorohexanone/smoke detector/water immersion',
      '34': 'And/or off grid',
      '35': 'Manual switching (optional)',
      '36': 'anti-reflux',
      '37': 'Support (optional)',
      '38': 'RS485/CAN/WiFi, Optional: Ethernet/4G',
      '39': 'Product category',
      '40': 'Liquid-cooled container',
      '41': 'Rated energy',
      '42': 'Rated power',
      '43': 'Rated output voltage',
      '44': 'Cell specification',
      '45': 'Cell combination method',
      '46': 'Maximum discharge current',
      '47': 'Maximum charging current',
      '48': 'Charging temperature',
      '49': 'Discharge temperature',
      '50': 'Communication mode',
      '51': 'Thermal management mode',
      '52': 'Liquid cooling',
      '53': 'Class of protection',
      '54': 'Functional safety',
      '55': 'weight',
      '56': 'dimension',
      '57': 'Application scenario',
      '58': 'Generation side/power grid side/User side',
      '59': 'Dc side',
      '60': 'Rated voltage',
      '61': 'Dc operating voltage range',
      '62': 'Recommended operating voltage range',
      '63': 'Ac side',
      '64': 'Alternating current voltage',
      '65': 'Maximum AC power',
      '66': 'Maximum alternating current',
      '67': 'Power factor',
      '68': 'System parameter',
      '69': 'Operating ambient temperature',
      '70': '-30℃~50℃ (frequency drop above 45℃)',
      '71': 'Storage ambient temperature',
      '72': 'Relative humidity of the working environment',
      '73': '0~100%（No condensation）',
      '74': 'Fire fighting mode',
      '75': 'Electric core grade + Cabinet slow fire (perfluorohexanone)/aerosol + water fire',
      '76': 'dilatation',
      '77': 'Flexible capacity expansion on the AC side (up to 6 parallel) and flexible capacity expansion on the DC side (energy storage cabinet +3 battery cabinet)',
      '78': 'And off-grid switching',
      '79': 'Support automatic switching',
      '80': 'wiring',
      '81': 'Quick-plug connector',
      '82': 'Size (Width * depth * height)',
      '83': 'Anticorrosion grade',
      '84': 'C3 (C4/C5 optional)',
      '85': 'Working altitude',
      '86': '≤4000m (frequency reduction above 2000m)',
      '87': 'Installation mode',
      '88': 'Fall to the ground',
      '89': 'Communication mode',
      '90': 'Ethernet、Dry contact',
      '91': 'Standard certification',
      '92': 'Rated output power',
      '93': 'Number of batteries',
      '94': 'Battery total energy',
      '95': 'Usable Energy',
      '96': 'Ambient temperature range',
      '97': 'Allowable relative humidity range',
      '98': 'Max. operating altitude',
      '99': 'Display',
      '100': 'Communication',
      '101': 'RS485/CAN/Wifi，Optional: Ethernet/4G',
      '102': 'Product ordering model',
      '103': 'Model',
      '104': 'Rated battery voltage',
      '105': 'Max. charge/discharge current',
      '106': 'Max. PV input power',
      '107': 'Max. input voltage',
      '108': 'Rated input voltage',
      '109': 'MPPT operating voltage range',
      '110': 'Number of MPPTs',
      '111': 'Max. input current per MPPT',
      '112': 'Max. short circuit current',
      '113': 'Grid voltage range',
      '114': '180Vac-276Vac (According to local standard)',
      '115': 'Rated AC power',
      '116': 'Max. AC power output to utility grid',
      '117': 'Rated voltage, frequency (off-grid)',
      '118': 'Rated power (off-grid)',
      '119': 'Max. apparent power (off-grid)',
      '120': 'Peak output power, duration (off-grid)',
      '121': 'Switch time',
      '122': 'Max effciency of solar inverter',
      '123': 'European efficiency of solar inverter',
      '124': 'Topology',
      '125': 'Transformerless',
      '126': 'Model',
      '127': 'Battery type',
      '128': 'Battery module energy',
      '129': 'Rated Power',
      '130': 'Transformer isolation',
      '131': 'Recommended max. PV input power',
      '132': 'Start-up voltage',
      '133': 'Max. number of input strings per MPPT',
      '134': 'Max. short-circuit current per MPPT',
      '135': 'Rated output current',
      '136': 'Max. output apparent power',
      '137': 'Max. output current',
      '138': 'THDi',
      '139': 'Power factor',
      '140': 'Lithium-ion & Lead-acid',
      '141': '1 default (+/-0.8 adjustable)',
      '142': 'Battery voltage range',
      '143': 'Number of battery input channels',
      '144': 'Max. charge/discharge power',
      '145': 'BMS communication mode',
      '146': 'Rated output frequency',
      '147': 'Peak output apparent power.time',
      '148': 'THDv (@ linear load)',
      '149': 'Max. MPPT efficiency',
      '150': 'Max. efficiency of charging/discharging',
      '151': 'DC switch',
      '152': 'Yes',
      '153': 'PV reverse connection protection',
      '154': 'Battery reverse connection protection',
      '155': 'Output short circuit protection',
      '156': 'Output overcurrent protection',
      '157': 'Output overvoltage protection',
      '158': 'Insulation impedance detection',
      '159': 'Residual current detection',
      '160': 'Anti-island protection',
      '161': 'Surge protection',
      '162': 'Operating temperature range',
      '163': 'Relative humidity range',
      '164': 'Standby self-consumption',
      '165': 'Wall-mounted',
      '166': 'Natural',
      '167': 'Forced airflow',
      '168': 'Communication'
    }
  }
}