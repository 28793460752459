<template>
  <div id="page">
    <img class="banner" src="@/assets/images/solution/cases/more/banner_2.png" alt="">
    <section>
      <div class="title">
        1、什么是工商业储能
      </div>
      <div class="collapse-card-list">
        <collapse-card value="工商业储能是面向工业用户的大号充电宝（电池系统），其核心的作用是在低电价时充电，在高电价时放电，通过峰谷价差实现能源管理收益，工商业储能系统的经济性还体现在："></collapse-card>
        <collapse-card value="（1）作为备用电源应付短时间限电"></collapse-card>
        <collapse-card value="（2）结合能源管理优化排班，降低人力成本"></collapse-card>
        <collapse-card value="（3）动态扩容，降低变压器费用"></collapse-card>
      </div>
    </section>
    <section>
      <div class="title">
        2、工商业储能系统是怎么工作的？
      </div>
      <div class="collapse-card-list">
        <collapse-card value="大多数情况下，工商业储能系统主要由以下几个部分组成："></collapse-card>
        <collapse-card value="（1）储能电池（Battery）：是储能系统的核心，用于存储和释放电能。目前，磷酸铁锂电池因其高安全性、高循环寿命和成本效益而成为储能场景的首选。"></collapse-card>
        <collapse-card value="（2）储能变流器（Power Conversion System, PCS）：控制蓄电池的充电和放电过程，进行交直流的变换。PCS由DC/AC双向变流器、控制单元等构成，可以接收能量管理系统(EMS)的控制指令，对电池进行充电或放电。"></collapse-card>
        <collapse-card value="（3）电池管理系统（Battery Management System, BMS）：负责对电池进行全面管理，确保电池安全、稳定地运行。BMS可以实时监控电池的温度、充放电状态，并对故障进行诊断，预测电池的寿命和维护需求。"></collapse-card>
        <collapse-card value="（4）能量管理系统（Energy Management System, EMS）：负责数据采集、网络监控和能量调度等。EMS可以确保储能系统的能量最大化利用，优化电池的充放电计划，提高储能系统的运行效率和可靠性。"></collapse-card>
        <collapse-card value="（5）其他配套电气设备：包括变压器、机架、连接线缆、汇流柜、防雷及接地系统、监控及报警系统等，系统进行模块化设计，系统电压、容量灵活配置。"></collapse-card>
        <collapse-card value="工商业储能系统由上述部分构成，一般最终形态为储能一体机（底面积+高度，大小标定）和集装箱式储能柜（面积大小标定）。系统经过安装、部署和调试，结合当地电网电价政策及工业企业客户自身经营排期情况，设定最优化的能源管理策略进行有效的充电和放电（一般一天2次充电2次放电）"></collapse-card>
      </div>
    </section>
    <section>
      <div class="title">
        3、什么样的业主（企业）适合安装和使用储能系统？
      </div>
      <div class="collapse-card-list">
        <collapse-card value="随着电芯供给充足，储能系统的度电成本已经显著降低，一般具备以下几个条件的企业推荐上储能系统："></collapse-card>
        <collapse-card value="（1）年电费金额在数百万以上规模（一般500万以上）"></collapse-card>
        <collapse-card value="（2）变压器容量具有一定的冗余（注：具体评估需要15分钟单位用电负荷曲线）"></collapse-card>
        <collapse-card value="（3）场地产权清晰且稳定"></collapse-card>
        <collapse-card value="（4）具备一定的安装空间（一体机：占地表面积2平米，高2m，左右间隔10cm以上，前后间隔1m以上，需要在配电房新增一台并网柜）"></collapse-card>
        <collapse-card value="（5）稳定的经营状况"></collapse-card>
        <collapse-card value="（6）适配的电价政策（目前优先考虑 江浙沪、广东地区）"></collapse-card>
      </div>
    </section>
    <section>
      <div class="title">
        4、业主需要做什么？
      </div>
      <div class="collapse-card-list">
        <collapse-card value="（1）业主一旦确定要上储能系统，需要第一时间提供电费核查联及用电负荷曲线（ 15分钟单位）；（有光伏发电的业主需要额外说明光伏发电情况）"></collapse-card>
        <collapse-card value="（2）我方生成初步方案和收益预测"></collapse-card>
        <collapse-card value="（3）现场勘探，确认安装条件，与客户沟通运营需求"></collapse-card>
        <collapse-card value="（4）修正方案，形成可签约合同。（注：分为 业主投资 / 运营方投资 / 双方共同投资等合作方案）"></collapse-card>
        <collapse-card value="（5）进入合同实质阶段，生产、安装、调试、交付"></collapse-card>
        <collapse-card value="（6）在线运维，定期拜访，根据客户生产需求的调整响应充放电策略调整"></collapse-card>
      </div>
    </section>
    <section>
      <div class="title">
        5、我们是谁？能为您提供哪些独特的价值？
      </div>
      <div class="collapse-card-list">
        <collapse-card value="Noether Energy Innovation 诺思新创 (上海)能源技术有限公司，核心团队有丰富的分布式能源互联网聚合管理经验，核心人员曾于海外成功创立过 VPP 虚拟电厂平台，后来项目被 Shell 和 Engie 并购。公司致力于整合产业链资源搭建业务平台（整合了“产品”、“工程”、“金融”三个领域的战略合作伙伴），开发行业领先的 AI 驱动型 EMS 能源管理系统，为民用及工商业用户提供个性化、高性能及全生命周期的储能系统解决方案。在工商业储能领域NoetherEnergyInnovation专注于两端提供专业服务"></collapse-card>
        <collapse-card value="（1）售前：整合行业优质供应链，为客户提供一站式软硬件系统解决方案"></collapse-card>
        <collapse-card value="（2）能源管理服务：实时采集电网政策数据，结合业主实际经营情况，实时调整EMS管理策略实现收益的最优化"></collapse-card>
      </div>
    </section>
    <section>
      <div class="title">
        6、联系我们
      </div>
      <div class="collapse-card-list">
        <collapse-card value="如果您是潜在的工商业储能项目合作方，可以联系我运营人员进行沟通，我们将为您提供高效的对接服务。"></collapse-card>
      </div>
    </section>
    <section>
      <div class="business-card">
        <img src="@/assets/images/solution/cases/more/picture-1.png" alt="">
        <img src="@/assets/images/solution/cases/more/picture-2.png" alt="">
      </div>
    </section>
  </div>
</template>

<script>
import CollapseCard from "@/components/CollapseCard.vue";
export default {
  name: 'MorePage',
  components: {
    CollapseCard
  },
  beforeCreate() {
    this.$store.commit('setIsShowHeader', { isShowHeader: true})
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
}

@media screen and (min-width: 1401px) {
  section {
    margin: auto;
    .title {
      font-size: 3.6rem;
      text-align: center;
      margin-top: 8.4rem;
    }
  }
  .collapse-card-list {
    margin: auto;
    width: 105rem;
    .collapse-card {
      margin-top: 4.6rem;
    }
  }
  .business-card {
    width: 105rem;
    display: flex;
    gap: 8.2rem;
    margin: 4.2rem auto 15rem;

    img {
      flex: 1;
      width: calc(-8.2rem + 50%);
      border: 1px solid #ddd;
      border-radius: 6px;
    }
  }
}
@media screen and (min-width: 701px) and (max-width: 1400px) {
  section {
    margin: auto;
    .title {
      font-size: 3.7rem;
      text-align: center;
      font-family: "Microsoft YaHei",serif;
      margin-top: 8.4rem;
      font-weight: bold;
    }
  }
  .collapse-card-list {
    margin: 0 8rem;
    //width: 105rem;

    .collapse-card {
      margin-top: 4.6rem;
    }
  }
  .business-card {
    //width: 105rem;
    display: flex;
    gap: 8.2rem;
    margin: 4.2rem 8rem 15rem;

    img {
      flex: 1;
      width: calc(-8.2rem + 50%);
      border: 1px solid #ddd;
      border-radius: 6px;
    }
  }
}
@media screen and (max-width: 700px) {
  section {
    margin: auto;
    .title {
      font-size: 3.6rem;
      text-align: center;
      margin-top: 8.4rem;
    }
  }
  .collapse-card-list {
    margin: 0 4rem;
    .collapse-card {
      margin-top: 4.6rem;
    }
  }
  .business-card {
    display: flex;
    gap: 8.2rem;
    margin: 4.2rem 4rem 15rem;

    img {
      flex: 1;
      width: calc(-8.2rem + 50%);
      border: 1px solid #ddd;
      border-radius: 6px;
    }
  }
}
//
//.doc {
//  padding: 5rem 0;
//}

</style>
<script setup>
</script>