<template>
  <div>
    <section id="section1" data-aos="zoom-out" :style="{ height: $calcHeight(760) }">
      <div id="content">
        <div class="title" data-aos="fade-up" data-aos-duration="800">{{ $t('menu.commercialCases') }}</div>
        <div class="sub-title" data-aos="fade-up" data-aos-duration="800">{{ $t('title.sub[32]') }}</div>
      </div>
    </section>
    <section id="section2">
      <div class="title" data-aos="fade-right">{{ $t('menu.solution') }}</div>
      <div class="detail" data-aos="fade-left">{{ $t('text[8]') }}</div>
    </section>
    <section id="section3">
      <div class="title" data-aos="fade-down">{{ $t('title[6]') }}</div>
      <div id="content">
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/commercial/icon-1.png" alt="icon-1" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[7]') }}</div>
          <div class="detail">{{ $t('text[9]') }}</div>
        </div>
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/commercial/icon-2.png" alt="icon-2" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[8]') }}</div>
          <div class="detail">
            <div>{{ $t('text[10]') }}</div>
            <div>{{ $t('text[11]') }}</div>
            <div>{{ $t('text[52]') }}</div>
            <div>{{ $t('text[12]') }}</div>
          </div>
        </div>
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/commercial/icon-3.png" alt="icon-3" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[9]') }}</div>
          <div class="detail">{{ $t('text[13]') }}</div>
        </div>
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/commercial/icon-4.png" alt="icon-4" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[10]') }}</div>
          <div class="detail">{{ $t('text[14]') }}</div>
        </div>
      </div>
    </section>
    <section id="section4">
      <div class="title" data-aos="fade-down">{{ $t('title[8]') }}</div>
      <div id="content" data-aos="fade-up">
        <div class="module">
          <div class="image" :style="{ height: $isBigScreen ? $calcHeight(354) : 'auto' }">
            <img src="@/assets/images/solution/commercial/picture-1.png" alt="picture-1" :width="$isBigScreen ? 'auto' : '100%'" :height="$isBigScreen ? '100%' : 'auto'" />
          </div>
          <div class="detail">{{ $t('title.sub[14]') }}</div>
          <div class="detail">{{ $t('title.sub[15]') }}</div>
          <div class="detail">{{ $t('title.sub[16]') }}</div>
        </div>
        <div class="module">
          <div class="image" :style="{ height: $isBigScreen ? $calcHeight(354) : 'auto' }">
            <img src="@/assets/images/solution/commercial/picture-2.png" alt="picture-2" :width="$isBigScreen ? 'auto' : '100%'" :height="$isBigScreen ? '100%' : 'auto'" />
          </div>
          <div class="detail">{{ $t('title.sub[17]') }}</div>
        </div>
        <div class="module">
          <div class="image" :style="{ height: $isBigScreen ? $calcHeight(354) : 'auto' }">
            <img src="@/assets/images/solution/commercial/picture-3.png" alt="picture-3" :width="$isBigScreen ? 'auto' : '100%'" :height="$isBigScreen ? '100%' : 'auto'" />
          </div>
          <div class="detail">{{ $t('title.sub[18]') }}</div>
        </div>
      </div>
    </section>
    <section id="section5">
      <div class="title" data-aos="fade-down">{{ $t('title[9]') }}</div>
      <div id="content">
        <div class="module" data-aos="fade-down-right">
          <div class="sub-title">{{ $t('title.sub[19]') }}</div>
          <div class="detail">{{ $t('text[15]') }}</div>
        </div>
        <div class="module" data-aos="fade-down-left">
          <div class="sub-title">{{ $t('title.sub[20]') }}</div>
          <div class="detail">{{ $t('text[16]') }}</div>
        </div>
        <div class="module" data-aos="fade-up-right">
          <div class="sub-title">{{ $t('title.sub[21]') }}</div>
          <div class="detail">{{ $t('text[17]') }}</div>
        </div>
        <div class="module" data-aos="fade-up-left">
          <div class="sub-title">{{ $t('title.sub[22]') }}</div>
          <div class="detail">{{ $t('text[18]') }}</div>
        </div>
      </div>
    </section>
    <section id="section6">
      <div class="title" data-aos="fade-up">{{ $t('title[7]') }}</div>
      <div id="content">
        <div class="module" data-aos="fade-up" @click="handleClick(0)">
          <div class="title">{{ $t('title.sub[11]') }}</div>
          <div class="tip">{{ $t('tip[1]') }}</div>
          <div class="image">
            <img src="@/assets/images/solution/commercial/product-1.png" alt="product-1" width="100%" height="auto" />
          </div>
        </div>
        <div class="module" data-aos="fade-up" @click="handleClick(1)">
          <div class="title">{{ $t('title.sub[12]') }}</div>
          <div class="tip">{{ $t('tip[1]') }}</div>
          <div class="image">
            <img src="@/assets/images/solution/commercial/product-2.png" alt="product-2" width="100%" height="auto" />
          </div>
        </div>
        <div class="module" data-aos="fade-up" @click="handleClick(2)">
          <div class="title">{{ $t('title.sub[13]') }}</div>
          <div class="tip">{{ $t('tip[1]') }}</div>
          <div class="image">
            <img src="@/assets/images/solution/commercial/product-3.png" alt="product-3" width="100%" height="auto" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {

  name: 'Household',

  data() {
    return {

    }
  },

  methods: {
    handleClick(type) {
      this.$router.push('/product/commercial/' + type);
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  box-sizing: border-box;

  .title {
    font-size: 4rem;
    font-weight: bold;
    white-space: nowrap;
  }

  .sub-title {
    font-size: 3rem;
    font-weight: bold;
  }
}

#section1 {
  width: 100%;
  background-image: url('../../assets/images/solution/commercial/section-1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: #FFFFFF;
  position: relative;

  #content {
    position: absolute;
    top: 20%;

    .sub-title {
      margin-top: 2rem;
    }
  }
}

#section2 {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
}

#section3 {
  width: 100%;
  background-image: url('../../assets/images/solution/commercial/section-3.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  #content {
    display: grid;
    justify-content: space-between;

    .module {
      background-color: #FFFFFF;
      border-radius: 10px;
      text-align: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &:hover {
        transform: translateY(-1rem);
        transition: transform 0.4s;
        outline: 2px solid #3fb74f;
        box-shadow: 0 2rem 2.7rem rgba(123,143,194,0.4);
      }

      .image {
        width: 6.2rem;
        height: 6.2rem;
      }
    }
  }
}

#section4 {
  width: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #content {
    display: flex;

    .module {
      text-align: center;
    }

    .text {
      margin-top: 1rem;
    }
  }
}

#section5 {
  width: 100%;
  background-image: url('../../assets/images/solution/commercial/section-5.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #content {
    display: grid;

    .module {
      .sub-title {
        margin-bottom: 1.5rem;
      }

      .detail {
        line-height: 3rem;
      }
    }
  }
}

#section6 {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7rem;
  box-sizing: border-box;

  #content {
    display: flex;
    gap: 5rem;

    .module {
      background-color: #F3F4F6;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 15px;

      &:hover {
        outline: 2px solid #3fb74f;
      }

      .tip {
        color: #85BD7E;
      }

      .image {
        width: 50%;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        &:hover {
          transform: scale(1.1);
          transition: all 0.6s;
        }
      }
    }
  }
}

@media screen and (min-width: 1401px) {
  section {
    padding: 6rem 12%;

    .detail {
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }

  #section2 {
    justify-content: space-between;
    align-items: center;
    gap: 28%;
  }

  #section3 {
    gap: 6rem;

    #content {
      grid-template-columns: repeat(4, 1fr);
      gap: 2.5rem;

      .module {
        width: 100%;
        padding: 3.5rem 2rem;

        .sub-title {
          margin-top: 2rem;
        }

        .detail {
          margin-top: 2rem;
        }
      }
    }
  }

  #section4 {
    gap: 6rem;

    #content {
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;

      .text {
        font-size: 1.6rem;
      }
    }
  }

  #section5 {
    gap: 6rem;

    #content {
      grid-template-columns: repeat(2, 1fr);
      gap: 5rem 17rem;
    }
  }

  #section6 {
    #content {
      justify-content: space-between;

      .module {
        width: 50%;
        gap: 2rem;
        padding: 3rem 0;

        .tip {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media screen and (min-width: 701px) and (max-width: 1400px) {
  section {
    padding: 6rem 8%;

    .title {
      font-size: 2.4rem;
    }

    .sub-title {
      font-size: 1.8rem;
    }

    .detail {
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }

  #section2 {
    justify-content: space-between;
    align-items: center;
    gap: 28%;
  }

  #section3 {
    gap: 2.5rem;

    #content {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;

      .module {
        width: 100%;
        min-height: 24rem;
        padding: 3rem 2rem;
        box-sizing: border-box;

        .sub-title {
          font-size: 2.2rem;
          margin-top: 1rem;
        }

        .detail {
          font-size: 1.6rem;
          line-height: 2.5rem;
          margin-top: 1rem;
        }
      }
    }
  }

  #section4 {
    gap: 4rem;

    #content {
      justify-content: space-between;
      align-items: flex-start;
      gap: 1rem;

      .text {
        font-size: 1.6rem;
      }
    }
  }

  #section5 {
    gap: 4rem;

    #content {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem 6rem;

      .module {
        .sub-title {
          font-size: 2.2rem;
          line-height: 2.4rem;
        }
      }
    }
  }

  #section6 {
    gap: 4rem;

    #content {
      justify-content: space-between;
      gap: 2rem;

      .module {
        width: 50%;
        gap: 1rem;
        padding: 3rem 0;

        .tip {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  section {
    padding: 6rem 4%;

    .detail {
      font-size: 3.2rem;
      line-height: 5rem;
    }
  }

  #section2 {
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
  }

  #section3 {
    gap: 4rem;

    #content {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;

      .module {
        width: 100%;
        min-height: 32rem;
        padding: 3rem 2rem;
        box-sizing: border-box;

        .sub-title {
          margin-top: 1rem;
        }

        .detail {
          font-size: 2.7rem;
          line-height: 3.5rem;
          margin-top: 1rem;
        }
      }
    }
  }

  #section4 {
    gap: 2.5rem;

    #content {
      flex-direction: column;
      gap: 4rem;
    }
  }

  #section5 {
    gap: 2.5rem;

    #content {
      grid-template-columns: 1fr;
      gap: 3rem;

      .detail {
        font-size: 2.7rem;
        line-height: 3.5rem;
      }
    }
  }

  #section6 {
    #content {
      flex-direction: column;

      .module {
        width: 100%;
        gap: 1rem;
        padding: 6rem 0;

        .tip {
          font-size: 3.2rem;
        }
      }
    }
  }
}
</style>