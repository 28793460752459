import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/views/Home.vue';

import SolutionHousehold from '@/views/solution/Household.vue';
import SolutionCommercial from '@/views/solution/Commercial.vue';
import SolutionCommercialCases from '@/views/solution/SolutionCommercialCases.vue'
import MorePage from "@/views/solution/MorePage.vue";

import ProductHouseholdSingle from '@/views/product/household/single/Index.vue';
import ProductHouseholdThree from '@/views/product/household/three/Index.vue';
import ProductCommercialModule1 from '@/views/product/commercial/Module1.vue';
import ProductCommercialModule2 from '@/views/product/commercial/Module2.vue';
import ProductCommercialModule3 from '@/views/product/commercial/Module3.vue';

import About from '@/views/About.vue';

import NotFound from '@/views/NotFound.vue';

Vue.use(Router);

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

export default new Router({
  routes: [
    { path: '/', name: 'Home', component: Home },
    
    { path: '/solution/household', name: 'SolutionHousehold', component: SolutionHousehold },
    { path: '/solution/commercial', name: 'SolutionCommercial', component: SolutionCommercial },
    { path: '/solution/commercial_cases', name: 'SolutionCommercialCases', component: SolutionCommercialCases },
    
    { path: '/product/household/0', name: 'ProductHouseholdSingle', component: ProductHouseholdSingle },
    { path: '/product/household/1', name: 'ProductHouseholdThree', component: ProductHouseholdThree },
    { path: '/product/commercial/0', name: 'ProductCommercial1', component: ProductCommercialModule1 },
    { path: '/product/commercial/1', name: 'ProductCommercial2', component: ProductCommercialModule2 },
    { path: '/product/commercial/2', name: 'ProductCommercial3', component: ProductCommercialModule3 },
    
    { path: '/about', name: 'About', component: About },
    { path: '/more', name: 'MorePage', component: MorePage },
    
    { path: '*', name: 'NotFound', component: NotFound }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})