<template>
  <div>
    <img class="banner" src="@/assets/images/solution/cases/banner_1.png" alt="">
    <section>
      <img v-if="$screenWidth > 1400" class="part-left bg" src="@/assets/images/solution/cases/1.png" alt="">
      <img v-else-if="$screenWidth < 750" class="part-left bg" src="@/assets/images/solution/cases/1_origin.png" alt="">
      <div v-else class="part-left">
      </div>
      <div class="part-right">
        <div data-aos="fade-right" class="title">
          围绕降本增效的大趋势
        </div>
        <div data-aos="fade-left" class="detail">
          <p>
            随着电力市场的逐步成熟和电力需求的不断增长，电力成本呈不断上升趋势。
            如果您是？
          </p>
          <p>
            企业通过引入储能系统，来实现电力需求的优化管理，提高电力利用效率，降低能源成本。主要降本增效的方式如：峰谷套利、需量管理等。
          </p>
          <p>
            通过使用储能系统也有助于企业减少对传统能源的依赖，降低碳排放和环境污染，符合环保需求。
          </p>
        </div>
      </div>
    </section>
    <section data-aos="fade-up">
      <img v-if="$screenWidth > 1400" class="part-left bg" src="@/assets/images/solution/cases/2.png" alt="">
      <div v-else class="bg"></div>
      <div class="cover">
        <div class="title title-l2 text-center">
          如果您是?
        </div>
        <div class="card-list">
          <div class="card">
            <img src="@/assets/images/solution/cases/icon_card_1.png" alt="">
            <span>企业主</span>
          </div>
          <div class="card">
            <img src="@/assets/images/solution/cases/icon_card_2.png" alt="">
            <span>工厂管理者</span>
          </div>
          <div class="card">
            <img src="@/assets/images/solution/cases/icon_card_3.png" alt="">
            <span>专业能源管理团队</span>
          </div>
          <div class="card">
            <img src="@/assets/images/solution/cases/icon_card_4.png" alt="">
            <span>园区管理者</span>
          </div>
        </div>
        <div>
          小诺将为您提供一站式、个性化, 工商业储能解决方案和服务!
        </div>
      </div>
    </section>
    <section data-aos="fade-up">
      <div>
        <div data-aos="zoom-in" class="part-left">
          中小型企业主
        </div>
        <div class="part-right">
          <p>
            贾老板是浙江一家中型纺织厂的负责人，多年来，他凭借着敏锐的市场洞察力和不懈的努力，将纺织厂经营得有声有色。然而，随着市场竞争的加剧和原材料价格的上涨，纺织厂的利润空间逐渐被压缩。特别是在冬季备货阶段，由于资金回笼周期长，贾老板面临着巨大的资金压力。
          </p>
        </div>
      </div>
      <div>
        <img v-if="$screenWidth > 1400" class="part-left bg" src="@/assets/images/solution/cases/3.png" alt="">
        <img v-else-if="$screenWidth < 750" class="part-left bg" src="@/assets/images/solution/cases/3_origin.png" alt="">
        <div v-else class="bg"></div>
        <div class="cover">
          <div class="detail">
            <div class="title title-l3">
              问题与挑战：
            </div>
            <p>
              贾老板深知，要缓解资金压力，就必须从各个方面降低成本。他注意到，纺织厂的电费支出一直居高不下。2023年，纺织厂的合计用电量为6460719千瓦时，电费高达497.8万元。这笔庞大的电费支出，让贾老板倍感压力。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              寻求解决方案----遇见小诺：
            </div>
            <p>
              小诺的峰谷套利经济模型，基于先进的储能技术和电力调度算法正是贾老板减少能源开销的强有力助手。通过部署6台100kw/215kWh储能机组，并在电价低谷时段充电、电价高峰时段放电的方式，实现电费的最优化。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              实施与效果：
            </div>
            <p>
              在小诺的支持下，通过两充两放的策略，纺织厂在电价较低的时段储存电能，在电价较高的时段使用储存的电能。经过一年的实施，2023年全年成功节省了55.75万元的电费。这对于资金回笼周期长的纺织厂来说，无疑是一笔巨大的财务节省。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section data-aos="fade-up">
      <div>
        <div data-aos="zoom-in" class="part-left">
          园区管理者
        </div>
        <div class="part-right">
          <p>
            在江苏的一座现代化工业园区，李经理担任着管理者的角色。他一直致力于打造一个绿色、高效、成本优化的商业环境，以吸引更多的创业者进驻，共同推动园区的繁荣发展。
          </p>
        </div>
      </div>
      <div>
        <img v-if="$screenWidth > 1400" class="part-left bg" src="@/assets/images/solution/cases/4.png" alt="">
        <img v-else-if="$screenWidth < 750" class="part-left bg" src="@/assets/images/solution/cases/4_origin.png" alt="">
        <div v-else class="bg"></div>
        <div class="cover">
          <div class="detail">
            <div class="title title-l3">
              问题与挑战：
            </div>
            <p>
              随着入驻企业的增多，园区的电力需求也日益增长。传统的电力供应模式不仅成本高昂，而且在高峰时段还可能出现电力短缺的问题。李经理深知这一问题对于园区长期发展的重要性。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              寻求解决方案----遇见小诺：
            </div>
            <p>
              在一次行业研讨会上，李经理了解到了储能技术的潜力和优势。 李经理迅速行动，与小诺合作，在园区内安装了光伏系统和储能机组。这些设备能够在白天将太阳能转化为电能，并在电价低谷时段储存电能。在电价高峰时段，园区可以利用储存的电能满足入驻企业的用电需求，避免了高昂的电费支出。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              实施与效果：
            </div>
            <p>
              随着储能技术的引入，园区的电力供应变得更加稳定可靠，电费也大幅下降。入驻企业纷纷表示，他们感受到了园区在能源管理方面的创新和用心，这让他们对园区的未来发展充满了信心。
              随着电费成本的降低和能源供应的保障，越来越多的创业者开始关注这个工业园区。他们看中了园区优越的地理位置、完善的基础设施以及绿色、高效的能源管理方案。许多创业者纷纷表示，他们愿意将自己的创业项目放在这里，与园区共同成长。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section data-aos="fade-up">
      <div>
        <div data-aos="zoom-in" class="part-left">
          工厂管理层
        </div>
        <div class="part-right">
          <p>
            在繁华的都市边缘，坐落着一家闻名遐迩的机械制造工厂。工厂在夏季的时候订单量暴增，生产线连轴转才能赶上交货日，正值夜班的繁忙时段，车间内机器轰鸣，工人们各司其职，忙碌地操作着生产线上的设备。
          </p>
        </div>
      </div>
      <div>
        <img v-if="$screenWidth > 1400" class="part-left bg" src="@/assets/images/solution/cases/5.png" alt="">
        <img v-else-if="$screenWidth < 750" class="part-left bg" src="@/assets/images/solution/cases/5_origin.png" alt="">
        <div v-else class="bg"></div>
        <div class="cover">
          <div class="detail">
            <div class="title title-l3">
              问题与挑战：
            </div>
            <p>
              突然间，整个车间陷入了一片黑暗，机器停止运转，原本有序的生产线瞬间停滞。突如其来的停电让所有人都措手不及，车间内顿时充满了紧张的气氛。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              寻求解决方案----遇见小诺：
            </div>
            <p>
              就在这时，工厂管理层做出了一个大胆的决定——启动储能设备（小诺）。原来，为了应对可能出现的电力供应问题，工厂今年刚刚投资建设了一套先进的储能系统。这套系统能够在电网断电的情况下，为工厂提供稳定的电力支持，确保生产线的正常运转。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              实施与效果：
            </div>
            <p>
              随着储能设备的启动，车间内逐渐恢复了光明。生产线上的机器重新开始运转，工人们也迅速回到了自己的岗位。避免了由于断电导致的生产延期、工人待工的困境。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section data-aos="fade-up">
      <div>
        <div data-aos="zoom-in" class="part-left">
          专业能源管理团队
        </div>
        <div class="part-right">
          <p>
            在国家的能源政策调整下，一项名为“尖电价”的新策略横空出世，对专业能源管理团队的技术革新产生巨大的挑战。
          </p>
        </div>
      </div>
      <div>
        <img v-if="$screenWidth > 1400" class="part-left bg" src="@/assets/images/solution/cases/6.png" alt="">
        <img v-else-if="$screenWidth < 750" class="part-left bg" src="@/assets/images/solution/cases/6_origin.png" alt="">
        <div v-else class="bg"></div>
        <div class="cover">
          <div class="detail">
            <div class="title title-l3">
              问题与挑战：
            </div>
            <p>
              对于电压等级为1-10千伏的某大工业两部制用户而言，这意味着他们将在尖峰时段面临高达1.36464元/kWh的电费，而相比之下，高峰电价和平时电价分别为1.1372元/kWh和0.6613元/kWh，低谷电价更是低至0.2768元/kWh。如此巨大的电价差异，让这家大工业企业的运营成本压力陡增。同时也给专业能源管理团队提出了更高的技术要求。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              寻求解决方案----遇见小诺：
            </div>
            <p>
              能源管理团队负责人迅速启用小诺的EMS系统，并在企业内部部署和应用。系统通过实时监测、数据分析和智能调度，精准地掌握了企业的用电规律和负荷特性。在尖峰电价时段，EMS系统自动调整生产线的工作模式，减少非必要用电设备的运行，同时将部分生产任务转移至低谷电价时段进行。此外，系统还优化了照明、空调等辅助设施的用电策略，确保每一度电都能得到高效利用。
            </p>
          </div>
          <div class="detail">
            <div class="title title-l3">
              实施与效果：
            </div>
            <p>
              在尖电价政策的推动下，一场能源管理革命正在悄然兴起。而小诺以其卓越的技术和解决方案，为能源管理团队提供了省心、省事、高效的能源管理方案。
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="btn-more-wrapper">
      <el-button class="btn-more" underline type="primary" @click="() => $router.push('/more')">我感兴趣，希望了解更多</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionCommercialCases',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
}

.text-center {
  text-align: center;
}


@media screen and (min-width: 1401px) {
  p {
    line-height: 2em;
  }
  section:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 18.4rem;

    .part-left {
      width: 50%;
      padding-bottom: 10rem;
    }

    .part-right {
      width: 50%;
      padding-right: 23.4rem;
      box-sizing: border-box;

      .title {
        margin-bottom: 3rem;
        font-size: 4.8rem;
      }
      .detail {
        font-size: 1.8rem;
        line-height: 3em;
        p {
          line-height: 3em;
        }
      }
    }
  }
  section:nth-child(3) {
    position: relative;

    .bg {
      width: 100%;
    }

    .cover {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      font-size: 2.4rem;

      .card-list {
        width: 140rem;
        display: flex;
        justify-content: space-between;
        //gap: 36px;

        .card {
          height: 27rem;
          width: 32rem;
          background-color: #FFFFFF;
          border-radius: 6px;
          font-size: 2.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 20px;
        }
      }

      .title {
        //line-height: 196px;
        font-size: 3.6rem;
      }
    }
  }
  section:nth-child(4) {
    &>div:first-child {
      display: flex;
      align-items: center;
      //gap: 20rem;
      padding: 6rem 12%;

      .part-left {
        font-size: 36px;
        width: 536px;
      }

      .part-right {
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        width: 100%;
      }

      .cover {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        font-size: 2.4rem;
        .detail {
          margin-left: 23.2rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  section:nth-child(5) {
    &>div:first-child {
      display: flex;
      align-items: center;
      //gap: 20rem;
      padding: 6rem 12%;

      .part-left {
        font-size: 3.6rem;
        width: 53.6rem;
      }

      .part-right {
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        width: 100%;
      }

      .cover {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        font-size: 2.4rem;
        .detail {
          margin-right: 23.2rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  section:nth-child(6) {
    &>div:first-child {
      display: flex;
      align-items: center;
      //gap: 20rem;
      padding: 6rem 12%;

      .part-left {
        font-size: 3.6rem;
        width: 53.6rem;
      }

      .part-right {
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        width: 100%;
      }

      .cover {
        color: #FFFFFF;
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        font-size: 2.4rem;
        .detail {
          margin-left: 23.2rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  section:nth-child(7) {
    &>div:first-child {
      display: flex;
      align-items: center;
      //gap: 20rem;
      padding: 6rem 12%;

      .part-left {
        font-size: 3.6rem;
        width: 53.6rem;
      }

      .part-right {
        font-size: 16px;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        width: 100%;
      }

      .cover {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        font-size: 2.4rem;
        .detail {
          margin-right: 23.2rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
}
@media screen and (min-width: 750px) and (max-width: 1400px) {
  $scale-rate: 0.23;
  .w {
    padding: 0 6rem;
    box-sizing: border-box;
  }
  section:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 9rem;
    padding: 10rem 0;
    height: 385px;

    .part-left {
      width: 38%;
      height: 100%;
      background-image: url("@/assets/images/solution/cases/1.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: 50%;
    }

    .part-right {
      box-sizing: border-box;
      width: 50%;

      .title {
        margin-bottom: 3rem;
        font-size: 2.4rem;
      }
      font-size: 1.6rem;
      line-height: 3.2rem;
    }
  }
  section:nth-child(3) {
    position: relative;

    .bg {
      width: 100%;
      height: 400px;
      background-image: url("@/assets/images/solution/cases/2_750.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .cover {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      font-size: 1.6rem;

      .card-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 7rem;

        .card {
          height: 12.7rem;
          width: 15.6rem;
          background-color: #FFFFFF;
          border-radius: 6px;
          font-size: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          gap: 2rem;
          img {
            width: 3.4rem;
          }
        }
      }

      .title {
        //line-height: 196px;
        font-size: 2rem;
      }
    }
  }
  section:nth-child(4) {
    &>div:first-child {
      display: flex;
      align-items: center;
      padding: 6rem 6rem;

      .part-left {
        font-size: 2.25rem;
        width: 20rem;
      }

      .part-right {
        flex: 1;
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        height: 700px;
        background-image: url("@/assets/images/solution/cases/3.png");
        background-repeat: no-repeat;
        background-size: cover;

        //background-position: center;
        background-position-x: 60%;
      }

      .cover {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        width: 60%;
        font-size: 1.85rem;
        .detail {
          margin-left: 6rem;
        }
        p {
          font-size: 1.6rem;
        }
        &:last-child {}
      }
    }
  }
  section:nth-child(5) {
    &>div:first-child {
      display: flex;
      align-items: center;
      padding: 6rem 6rem;

      .part-left {
        font-size: 2.25rem;
        width: 20rem;
      }

      .part-right {
        flex: 1;
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        height: 800px;
        background-image: url("@/assets/images/solution/cases/4.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x:40%;
      }

      .cover {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        right: 0;
        top: 0;
        height: 100%;
        width: 64%;
        font-size: 1.85rem;
        .detail {
          margin-right: 6.4rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  section:nth-child(6) {
    &>div:first-child {
      display: flex;
      align-items: center;
      padding: 6rem 6rem;

      .part-left {
        font-size: 2.25rem;
        width: 20rem;
      }

      .part-right {
        flex: 1;
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {


        width: 100%;
        height: 800px;
        background-image: url("@/assets/images/solution/cases/5.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 56%;
      }

      .cover {
        color: #FFFFFF;
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        width: 50%;
        font-size: 1.85rem;
        .detail {
          margin-left: 6.0rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  section:nth-child(7) {
    &>div:first-child {
      display: flex;
      align-items: center;
      padding: 6rem 6rem;

      .part-left {
        font-size: 2.25rem;
        width: 20rem;
      }

      .part-right {
        flex: 1;
        font-size: 1.6rem;
      }
    }
    &>div:nth-child(2) {
      position: relative;

      .bg {
        height: 800px;
        background-image: url("@/assets/images/solution/cases/6.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x:42%;
      }

      .cover {
        box-sizing: border-box;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        font-size: 1.85rem;
        .detail {
          margin-right: 5rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }

}
@media screen and (max-width: 749px) {
  $scale-rate: 0.23;
  .w {
    padding: 0 6rem;
    box-sizing: border-box;
  }
  section:nth-child(2) {
    padding: 5rem;
    .part-left {
      width: 100%;
    }

    .part-right {
      margin-top: 8rem;

      .title {
        margin-bottom: 3rem;
        font-size: 4.8rem;
      }

      font-size: 3.2rem;
      line-height: 5rem;
    }
  }
  section:nth-child(3) {
    position: relative;

    .bg {
      width: 100%;
      height: 80rem;
      background-image: url("@/assets/images/solution/cases/2_750.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .cover {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      font-size: 3.2rem;

      .card-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;

        .card {
          height: 21.2rem;
          width: 26rem;
          background-color: #FFFFFF;
          border-radius: 6px;
          font-size: 3.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          gap: 2rem;
          img {
            width: 3.4rem;
          }
        }
      }

      .title {
        //line-height: 196px;
        font-size: 4rem;
      }
    }
  }
  section:nth-child(4) {
    background-color: #F3F4F6;
    border-radius: 15px;
    margin: 5rem;
    padding: 5rem;
    &>div:first-child {
      .part-left {
        font-size: 5.5rem;
      }

      .part-right {
        flex: 1;
        font-size: 3.2rem;
      }
    }
    &>div:last-child {

      .bg {
        width: 100%;
      }

      .cover {
        margin-top: 8rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 3.7rem;
        .detail {
        }
        p {
          font-size: 3.2rem;
        }
        &:last-child {}
      }
    }

  }
  section:nth-child(5) {
    background-color: #F3F4F6;
    border-radius: 15px;
    margin: 5rem;
    padding: 5rem;
    &>div:first-child {
      .part-left {
        font-size: 5.5rem;
      }

      .part-right {
        flex: 1;
        font-size: 3.2rem;
      }
    }
    &>div:last-child {

      .bg {
        width: 100%;
      }

      .cover {
        margin-top: 8rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 3.7rem;
        .detail {
        }
        p {
          font-size: 3.2rem;
        }
        &:last-child {}
      }
    }
  }
  section:nth-child(6) {
    background-color: #F3F4F6;
    border-radius: 15px;
    margin: 5rem;
    padding: 5rem;
    &>div:first-child {
      .part-left {
        font-size: 5.5rem;
      }

      .part-right {
        flex: 1;
        font-size: 3.2rem;
      }
    }
    &>div:last-child {

      .bg {
        width: 100%;
      }

      .cover {
        margin-top: 8rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 3.7rem;
        .detail {
        }
        p {
          font-size: 3.2rem;
        }
        &:last-child {}
      }
    }
  }
  section:nth-child(7) {
    background-color: #F3F4F6;
    border-radius: 15px;
    margin: 5rem;
    padding: 5rem;
    &>div:first-child {
      .part-left {
        font-size: 5.5rem;
      }

      .part-right {
        flex: 1;
        font-size: 3.2rem;
      }
    }
    &>div:last-child {

      .bg {
        width: 100%;
      }

      .cover {
        margin-top: 8rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        left: 0;
        top: 0;
        height: 100%;
        font-size: 3.7rem;
        .detail {
        }
        p {
          font-size: 3.2rem;
        }
        &:last-child {}
      }
    }
  }
}
.title {
  font-weight: bold;
  white-space: nowrap;
}

.clearfix:after {
  content: '.';
  height: 0;
  display: block;
  clear: both;
}
.btn-more-wrapper {
  margin: 12rem 0;
  width: 100%;
  text-align: center;
  .btn-more {
    background-color: #85bd7e;
    font-size: 2.4rem;
    padding: 0 2rem;
    border-color: transparent;
    height: 6.6rem;
    //width: 18.8rem;
  }
}
</style>