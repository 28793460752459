<template>
  <div id="nav-menu" :style="{ top: $isBigScreen ? (isShowHeader ? '100px' : 0) : '60px' }" @mousemove="handleMousemove">
    <template v-for="(item, index) in menuItems">
      <div :class="['name', { 'is-active': activeIndex === index }]" :key="index" @click="handleMenuClick(index)">{{ item }}</div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  
  export default {
    
    name: 'NavMenu',
    
    props: {
      activeIndex: { type: Number, default: 0 },
      menuItems: { type: Array, default: () => [] }
    },
    
    data() {
      return {
        
      }
    },
    
    computed: {
      ...mapState(['isShowHeader'])
    },
    
    watch: {
      activeIndex(value) {
        window.scrollTo({ top: 0 });
      }
    },
    
    methods: {
      handleMenuClick(value) {
        this.$emit('select', value);
      },
      
      handleMousemove(event) {
        if(!this.isShowHeader && event.y === 0) {
          this.$store.commit('setIsShowHeader', { isShowHeader: true });
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #nav-menu {
    width: 100%;
    height: 60px;
    background-color: #393A3B;
    padding: 0 14%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    position: fixed;
    z-index: 999;
    transition: top 0.6s;
    
    .name {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
      color: #FFFFFF;
    }
    
    .is-active {
      color: #85BD7E;
    }
  }
</style>