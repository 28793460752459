<template>
  <div>
    <el-row type="flex" v-if="data.length === 1" justify="space-between" align="middle" style="background-color: #85BD7E;">
      <template v-if="text">
        <el-col :span="8" class="title big-font-size">{{ data[0] }}</el-col>
        <el-col :span="16" class="title-detail small-font-size">{{ text }}</el-col>
      </template>
      <template v-else>
        <el-col :span="24" class="title big-font-size">{{ data[0] }}</el-col>
      </template>
    </el-row>
    <el-row type="flex" v-else-if="data.length === 2">
      <el-col :span="8" class="th border-bottom">{{ data[0] }}</el-col>
      <el-col :span="16" class="td border-left border-bottom">{{ data[1] }}</el-col>
    </el-row>
    <el-row type="flex" v-else-if="data.length === 3">
      <el-col :span="8" class="th border-bottom">{{ data[0] }}</el-col>
      <el-col :span="8" class="td border-left border-bottom">{{ data[1] }}</el-col>
      <el-col :span="8" class="td border-left border-bottom">{{ data[2] }}</el-col>
    </el-row>
    <el-row v-else style="margin-top: 2.5rem; line-height: 3rem;">
      <el-col :span="24" class="small-font-size" v-for="(item, index) in data" :key="index">{{item}}</el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    
    name: 'TableTr',
    
    props: {
      text: { type: String, default: null },
      data: { type: Array, default: () => [] }
    },
    
    data() {
      return {
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    color: #FFFFFF;
    background-color: #85BD7E;
    padding: 1.5rem;
    box-sizing: border-box;
  }
  
  .title-detail {
    color: #FFFFFF;
    background-color: #85BD7E;
    padding: 1.5rem 5.5rem;
    box-sizing: border-box;
    line-height: 3rem;
  }
  
  .th {
    padding: 1rem 0;
    font-weight: bold;
  }
  
  .td {
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .border {
    &-top {
      border-top: 1px solid #C4C4C3;
    }
    &-bottom {
      border-bottom: 1px solid #C4C4C3;
    }
    &-left {
      border-left: 1px solid #C4C4C3;
    }
    &-right {
      border-right: 1px solid #C4C4C3;
    }
  }
  
  @media screen and (min-width: 1401px) {
    .th {
      font-size: 1.6rem;
    }
    
    .td {
      font-size: 1.4rem;
    }
    
    .big-font-size {
      font-size: 2rem;
    }
    
    .small-font-size {
      font-size: 1.4rem;
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    .th {
      font-size: 1.6rem;
    }
    
    .td {
      font-size: 1.4rem;
    }
    
    .big-font-size {
      font-size: 2rem;
    }
    
    .small-font-size {
      font-size: 1.4rem;
    }
  }
  
  @media screen and (max-width: 700px) {
    .th {
      font-size: 2.7rem;
    }
    
    .td {
      font-size: 2.4rem;
    }
    
    .big-font-size {
      font-size: 3rem;
    }
    
    .small-font-size {
      font-size: 2rem;
    }
  }
</style>