<template>
  <div class="collapse-card">
    <div ref="inner" class="collapse-card__inner" style="white-space: nowrap; height: 0; visibility: hidden; padding: 0">{{value}}</div>
    <div :class="{ 'collapse-card__inner': true, '--collapsed': isCollapsed }" @click="handleClick">
      <div>{{value}}</div>
      <template v-if="showIcon">
        <img v-if="isCollapsed" class="icon" src="@/assets/images/solution/cases/arrow_bottom.png" alt="">
        <img v-else class="icon" src="@/assets/images/solution/cases/arrow_bottom_active.png" alt="">
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapseCard",
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCollapsed: true,
      showIcon: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.$nextTick(() => {
          this.$refs.inner.scrollWidth > this.$refs.inner.offsetWidth ? this.showIcon = true : this.showIcon = false
        })
      },
      immediate: true
    }
  },
  methods: {
    handleClick() {
      if (this.showIcon) {
        this.isCollapsed = !this.isCollapsed
      }
    }
  },
}
</script>

<style scoped lang="scss">
.--collapsed {
  white-space: nowrap;

}
.collapse-card {
  background-color: #FFFFFF;
  &__inner {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 1rem;
    border: 1px solid #898d97;
    padding: 4rem 4.8rem;
    cursor: pointer;
    transition: all 0.3s ease;

    gap: 1rem;
    div:first-child {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .icon {
      height: 1em;
    }
    &:not(.--collapsed) {
      border: none;
      color: #85bd7e;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
      //background-image: url("@/assets/images/solution/cases/more/bg_card_750.png");
      //background-repeat: no-repeat;
      //background-size: 100% 100%;
    }

  }
  .child {
    margin-top: 4.6rem;
  }
}
@media screen and (min-width: 1401px) {
  .collapse-card {
    &__inner {
      font-size: 2.4rem;
    }
  }
}
@media screen and (min-width: 750px) and (max-width: 1400px){
  .collapse-card {
    &__inner {
      font-size: 3.2rem;
    }
  }
}
@media screen and (max-width: 749px) {
  .collapse-card {
    &__inner {
      font-size: 3.2rem;
    }
  }
}
</style>