import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isShowHeader: true
  },
  
  mutations: {
    setIsShowHeader(state, payload) {
      state.isShowHeader = payload.isShowHeader;
    }
  }
})