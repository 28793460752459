export default {
  menu: {
    home: '首页',
    solution: '解决方案',
    household: '户用储能',
    commercial: '工商业储能概述',
    commercialCases: '工商业储能技术和产品',
    product: '储能产品',
    householdProduct: '户用储能产品',
    commercialProduct: '工商业储能产品',
    about: '关于我们',
    single: '单相一体机',
    three: '三相一体机',
    overview: '硬件介绍',
    specification: '技术参数'
  },
  
  info: {
    record: '苏ICP备13007749号-8',
    copyright: 'Copyright © 2024 诺思新创（上海）能源科技有限公司'
  },
  
  title: {
    '1': '赋能未来，储智无限',
    '2': '一站式软硬件系统解决方案',
    '3': 'AI驱动型EMS能源管理系统',
    '4': '工商业储能解决方案',
    '5': '户用储能解决方案',
    '6': '方案特点',
    '7': '相关产品',
    '8': 'EMS平台（能源管理系统）',
    '9': '金融服务',
    '10': '产品优势',
    '11': '技术规格',
    '12': '更多细节',
    '13': '智能住宅储能系统',
    sub: {
      '1': '用创新技术，高效利用每一度电',
      '2': '灵活配置',
      '3': '智能高效',
      '4': '安全稳定',
      '5': '户用单相储能一体机',
      '6': '户用三相储能一体机',
      '7': '高效灵活',
      '8': '更低LCOS',
      '9': '极致安全',
      '10': '智能调度',
      '11': '交流液冷储能柜',
      '12': '液冷集装箱式储能柜',
      '13': '集成式工商业储能系统',
      '14': '可观（可视化）',
      '15': '可测（系统和算法预测）',
      '16': '可控（数字化、电子化的控制）',
      '17': '功率预测系统',
      '18': '新能源设备安全管理系统',
      '19': '设备投资：',
      '20': '设备租赁：',
      '21': '资产买卖：',
      '22': '收益分配：',
      '23': '高安全',
      '24': '高能效',
      '25': '长寿命',
      '26': '并离网',
      '27': '易配置',
      '28': '高收益',
      '29': '智能管理',
      '30': '外貌',
      '31': '智慧用电，安全节能',
      '32': '商业储能，智慧共享，安全高效'
    }
  },
  
  tip: {
    '1': '进一步了解详情 >'
  },
  
  text: {
    '1': '诺思新创（上海）能源技术有限公司，成立于2023年2月，是一家由储能行业和互联网行业背景的专业团队创立的公司，团队成员拥有丰富的储能行业和能源互联网聚合平台的开发运维经验。其中，两位核心人员曾在海外成功创立过 VPP 虚拟电厂公司，并被 Shell 和 Engie 并购。公司开发行业领先的AI 驱动型 EMS 能源管理系统，以储能领域相关软硬件系统解决方案为核心业务，产品和服务覆盖户用及工商业储能等丰富的应用场景。',
    '2': '家庭储能通过分布式光伏和储能的结合，在居民家中，通过太阳能电池板等新能源发电设备为家庭供电，同时对电量进行管理，将多余电量进行储存，并供给电网。不仅可作应急电源，更能为家庭节省电力开支，供一般家用电器、电脑、照明、通信设备等使用。',
    '3': '系统功率3~120kW',
    '4': '支持多机并联电池容量5kWh~240kWh',
    '5': '电力电子深度耦合，电池全生命周期可用容量提升6%',
    '6': 'APP与云端数据互联，随时随地运维',
    '7': '支持防逆流，可提供多种储能工作模式',
    '8': '工商业储能系统利用锂电池作为储能装置，通过本地及远端EMS管理系统，完成电网、电池、负载三者之间的电能提供和电能需求的平衡与优化，并能方便接入光伏等新能源设备，在峰谷用电、配网增容、用电安全、提升用电质量等方面带来应用价值，同时作为核心节点实现智能电网的接入。广泛应用于商业综合体，工厂，医院，数据中心等场景。',
    '9': '模块化设计，灵活扩容，支持自动并离网切换',
    '10': '主从架构设计，面积能量密度高',
    '11': '储能柜电池柜预接线',
    '12': '循环寿命长，度电成本低',
    '13': '水电分离，3+2安全体系',
    '14': '集成EMS功能：防逆流、削峰填谷、需量管理等',
    '15': '包括电池、储能变流器、能量管理系统等关键设备的采购和安装',
    '16': '储能设备的租赁服务通常包括设备的安装、维护和更新等，用户只需根据需要支付租赁费用。此外，还可能提供定制的租赁方案，以满足不同用户的需求',
    '17': '用户在资金实力允许的条件下，可随时买断储能设备',
    '18': '通过峰谷套利，节电省钱',
    '19': '适用于年电费＜1000万元的用户',
    '20': '适用于年电费≥1000万元的用户',
    '21': '模块化设计，灵活扩容支持自动并离网切换，适用于多场景',
    '22': '单相',
    '23': 'MPP跟踪器个数：2',
    '24': '最大PV输入电流16A',
    '25': '内置电池组平衡管理单元，增加电池容量',
    '26': '最大光电值电流输入：16A，适用于大电流/双面模块',
    '27': '堆叠一体化设计，方便安装和简单维护',
    '28': '电池侧完全带电隔离，使系统更安全',
    '29': '三相',
    '30': '双MPPT',
    '31': '最大充电和放电电流：50A',
    '32': '可扩展系统解决方案',
    '33': '最多10个并行系统',
    '34': '灵活的配置',
    '35': '最多2个MPPT，提供更灵活的配置',
    '36': '充电效率高',
    '37': '兼容高压电池，可支持充放电效率高。电池电压范围宽，适用性强',
    '38': '全面数字化运营',
    '39': '实现更高的控制精度',
    '40': '模块化和集成化设计',
    '41': '运输和安装方便',
    '42': '可扩展以扩展电池容量',
    '43': '测试条件:25℃，100%DOD, 0.2C充放电。',
    '44': '基于电池单元。',
    '45': '请参考温度降额曲线。',
    '46': '如果海拔> 2000m，需要降额，请参考降额曲线。',
    '47': '在一个系统有足够的光伏和电池电力。',
    '48': '请参考文档“SOFAR逆变器型号兼容电池清单”。',
    '49': '所有规格如有更改，恕不另行通知。',
    '50': '电池-交流电电池充放电的最大效率',
    '51': '待机损耗在额定输入电压下',
    '52': '无须现场敷设电缆'
  },
  
  table: {
    th: {
      '1': '产品型号',
      '2': '系统参数',
      '3': '逆变器模块',
      '4': '电池模块',
      '5': '标准',
      '6': '直流输入（PV）',
      '7': '交流输出（并网）',
      '8': '电池参数',
      '9': '交流输出（离网）',
      '10': '效率',
      '11': '保护',
      '12': '常规参数'
    },
    td: {
      '1': 'DC侧参数',
      '2': '电芯类型',
      '3': '系统电池电压范围',
      '4': '直流保护',
      '5': 'AC侧参数',
      '6': '额定交流功率',
      '7': '交流最大功率',
      '8': '额定电网电压',
      '9': '额定电网频率',
      '10': '电网类型',
      '11': '低压侧',
      '12': '电池柜尺寸(W*D*H)',
      '13': '电池柜重量',
      '14': '基本参数',
      '15': '最大系统效率',
      '16': '≥90%(额定工况)',
      '17': '充放电倍率',
      '18': '冷却方式',
      '19': '液冷系统',
      '20': 'PCS防护等级',
      '21': '系统防护等级',
      '22': '循环次数',
      '23': '运行温度范围',
      '24': '充电：0℃~50℃，放电：-20℃~55℃',
      '25': '制冷量功率',
      '26': '最高工作海拔',
      '27': '4000m(＞4000m定制)',
      '28': '噪音',
      '29': '通讯接口',
      '30': '以太网、RS485',
      '31': '充放电切换时间',
      '32': '消防系统',
      '33': '声光报警/气溶胶/全氟己酮/烟感探测器/水浸',
      '34': '并/离网',
      '35': '手动切换（选配）',
      '36': '防逆流',
      '37': '支持（选配）',
      '38': 'RS485/CAN/WiFi, 可选: 以太网/4G',
      '39': '产品类别',
      '40': '液冷集装箱',
      '41': '额定能量',
      '42': '额定功率',
      '43': '额定输出电压',
      '44': '电芯规格',
      '45': '电芯组合方式',
      '46': '最大放电电流',
      '47': '最大充电电流',
      '48': '充电温度',
      '49': '放电温度',
      '50': '通讯方式',
      '51': '热管理方式',
      '52': '液冷',
      '53': '防护等级',
      '54': '功能安全',
      '55': '重量',
      '56': '尺寸',
      '57': '适用场景',
      '58': '发电侧/电网侧/用户侧',
      '59': '直流侧',
      '60': '额定电压',
      '61': '直流工作电压范围',
      '62': '推荐工作电压范围',
      '63': '交流侧',
      '64': '交流电压',
      '65': '最大交流功率',
      '66': '最大交流电流',
      '67': '功率因素',
      '68': '系统参数',
      '69': '工作环境温度',
      '70': '-30℃~50℃（45℃以上降频）',
      '71': '存储环境温度',
      '72': '工作环境相对湿度',
      '73': '0~100%（无凝露）',
      '74': '消防方式',
      '75': '电芯级+柜缓气消防（全氟己酮）/气溶胶+水消防',
      '76': '扩容',
      '77': '交流侧灵活扩容（最大6并）、直流侧灵活扩容（储能柜+3电池柜）',
      '78': '并离网切换',
      '79': '支持自动切换',
      '80': '接线',
      '81': '快插拔连接器',
      '82': '尺寸(宽*深*高)',
      '83': '防腐等级',
      '84': 'C3（C4/C5选配）',
      '85': '工作海拔',
      '86': '≤4000m（2000m以上降频）',
      '87': '安装方式',
      '88': '落地',
      '89': '通信方式',
      '90': 'Ethernet、干接点',
      '91': '标准认证',
      '92': '标准输出功率',
      '93': '电池数量',
      '94': '电池总能量',
      '95': '可用能源',
      '96': '环境温度范围',
      '97': '允许相对湿度范围',
      '98': '最大工作高度',
      '99': '显示',
      '100': '通信',
      '101': 'RS485/蓝牙/Ethernet，可选: GPRS/无线网',
      '102': '产品订购模式',
      '103': '机型',
      '104': '标准电池电压',
      '105': '最大充/放电电流',
      '106': '最大PV输入功率',
      '107': '最大输入电压',
      '108': '额定输入电压',
      '109': 'MPPT工作电压范围',
      '110': 'MPPT数量',
      '111': '最大输入电流',
      '112': '最大短路电流',
      '113': '电网电压范围',
      '114': '180Vac-276Vac（根据当地标准）',
      '115': '额定输出功率',
      '116': '最大输出功率',
      '117': '离网额定电压频率',
      '118': '额定功率（离网）',
      '119': '离网最大输出功率',
      '120': '离网峰值输出功率，时间',
      '121': '切换时间',
      '122': '最大效率',
      '123': '欧洲效率',
      '124': '拓扑',
      '125': '无变压器',
      '126': '型号',
      '127': '电池类型',
      '128': '电池容量',
      '129': '正常功率',
      '130': '转换绝缘',
      '131': '最大推荐组件功率',
      '132': '启动电压',
      '133': '最大输入组串数',
      '134': '最大输入短路电流',
      '135': '额定输出电流',
      '136': '最大输出视在功率',
      '137': '最大输出电流',
      '138': '电流总谐波失真',
      '139': '功率因数',
      '140': '钮电池&铅酸电池',
      '141': '1（0.8超前~0.8滞后）',
      '142': '电池电压范围',
      '143': '电池输入路数',
      '144': '最大充/放电功率',
      '145': 'BMS通信方式',
      '146': '额定输出频率',
      '147': '峰值输出视在功率，时间',
      '148': '电压总谐波 失真（@线性 负载）',
      '149': 'MPPT最大效率',
      '150': '电池充放电 最大效率',
      '151': '直流开关',
      '152': '有',
      '153': 'PV反接保护',
      '154': '电池反接保护',
      '155': '输出短路保护',
      '156': '输出过流保护',
      '157': '输出过压保护',
      '158': '绝缘阻抗检测',
      '159': '残余电流检测',
      '160': '防孤岛保护',
      '161': '浪涌保护',
      '162': '工作温度范围',
      '163': '相对湿度范围',
      '164': '待机损耗',
      '165': '壁挂',
      '166': '自然散热',
      '167': '风冷',
      '168': '通讯'
    }
  }
}