<template>
  <div id="app">
    <!-- header -->
    <div id="header-big-screen" :class="['header', { 'show-header': isShowHeader, 'hidden-header': !isShowHeader }]" v-if="$isBigScreen">
      <img src="@/assets/images/logo.png" alt="logo" width="190px" height="auto">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" active-text-color="#85BD7E" @select="handleMenuSelect">
        <!-- 导航菜单 -->
        <el-menu-item v-for="(item, index) in navMenuItems" :key="index" :index="item.index">
          <div :class="{ 'active-menu': activeMenu === item.value, 'select-menu': selectedMenu === item.value }" @mouseenter="handleMenuMouseenter(item.value)" @mouseleave="handleMenuMouseleave(item.value)">
            {{ item.label }}
          </div>
        </el-menu-item>
        <!-- 中英文切换 -->
        <!-- <el-menu-item>
          <el-dropdown trigger="click" @command="handleSetLanguage">
            <div id="zh-en">
              <img src="@/assets/icons/zh-en.png" alt="zh-en" width="25px" height="25px" />
              <div style="color: #909399;">{{ language[$i18n.locale] }}</div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) of language" :key="index" :command="index">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item> -->
      </el-menu>
    </div>
    <div id="header-small-screen" class="header" v-else>
      <img src="@/assets/images/logo.png" alt="logo" width="100px" height="auto">
      <div @click="drawer = !drawer"><i :class="drawer ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i></div>
    </div>
    <!-- main -->
    <div id="main" :style="{ 'padding-top': mainPaddingTop, 'min-height': mainMinHeight }">
      <router-view></router-view>
    </div>
    <!-- footer -->
    <div id="footer">
      <div id="icp-record">
        <a href="http://beian.miit.gov.cn/" target="_blank">{{ $t('info.record') }}</a>
      </div>
      <div id="copyright">{{ $t('info.copyright') }}</div>
    </div>
    <!-- 菜单栏一级展开（户用 or 工商业） -->
    <div id="expand-submenu-1" :style="{ paddingRight: expandSubmenu1PaddingRight }" @mouseenter="handleExpand1Mouseenter()" @mouseleave="handleExpand1Mouseleave()">
      <template v-for="(item, index) in navSubmenuItems[activeMenu]">
        <div :key="index" :class="['menu-item', { 'active-submenu': activeSubmenu === item.value, 'select-submenu': selectedMenu === activeMenu && selectedSubMenu === item.value }]"
          @click="handleSubmenuSelect(item.value)" @mouseenter="handleSubmenuMouseenter(item.value)" @mouseleave="handleSubmenuMouseleave(item.value)">{{ item.label }}</div>
      </template>
    </div>
    <!-- 菜单栏二级展开（储能产品列表） -->
    <div id="expand-submenu-2" @mouseenter="handleExpand2Mouseenter()" @mouseleave="handleExpand2Mouseleave()">
      <div class="product" v-for="(item, index) in products[activeSubmenu]" :key="index" @click="handleProductSelect(index)">
        <div class="image"><img :src="item.src" :alt="index" width="80%" height="auto" /></div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <!-- 小屏展开右侧抽屉（导航菜单栏） -->
    <el-drawer :visible.sync="drawer" :with-header="false" custom-class="drawer" :size="220">
      <el-menu router unique-opened :default-active="activeIndex" active-text-color="#85BD7E" @select="drawer = false">
        <el-menu-item index="/">{{ $t('menu.home') }}</el-menu-item>
        <el-submenu index="/solution">
          <template slot="title">{{ $t('menu.solution') }}</template>
          <el-menu-item index="/solution/commercial">{{ $t('menu.commercial') }}</el-menu-item>
          <el-menu-item index="/solution/commercial_cases">{{ $t('menu.commercialCases') }}</el-menu-item>
          <el-menu-item index="/solution/household">{{ $t('menu.household') }}</el-menu-item>
        </el-submenu>
        <el-submenu index="/product">
          <template slot="title">{{ $t('menu.product') }}</template>
          <el-submenu index="/household">
            <template slot="title">{{ $t('menu.household') }}</template>
            <el-menu-item index="/product/household/0">{{ $t('menu.single') }}</el-menu-item>
            <el-menu-item index="/product/household/1">{{ $t('menu.three') }}</el-menu-item>
          </el-submenu>
          <el-submenu index="/commercial">
            <template slot="title">{{ $t('menu.commercial') }}</template>
            <el-menu-item index="/product/commercial/0">{{ $t('title.sub[11]') }}</el-menu-item>
            <el-menu-item index="/product/commercial/1">{{ $t('title.sub[12]') }}</el-menu-item>
            <el-menu-item index="/product/commercial/2">{{ $t('title.sub[13]') }}</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="/about">{{ $t('menu.about') }}</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import Constants from '@/Constants';
  
  const { Language } = Constants;
  
  export default {
    
    name: 'App',
    
    data() {
      return {
        language: Language,
        
        drawer: false,
        
        activeIndex: '/',
        
        activeMenu: null,
        activeSubmenu: null,
        
        selectedMenu: null,
        selectedSubMenu: null,
        
        mousePosition: null,
        
        isShowExpandSubmenu1: false,
        isShowExpandSubmenu2: false
      }
    },
    
    created() {
      // 设置根元素 (html) 的字体大小为 10px
      document.documentElement.style.fontSize = this.$calcHeight(this.$isBigScreen ? 10 : 20);
    },
    
    mounted() {
      // 监听页面滚动事件
      window.addEventListener("scroll", this.handleScroll);
      // 监听当前设备屏幕宽度变化
      window.onresize = () => {
        if(Math.abs(window.innerWidth - this.$screenWidth) > 100) {
          location.reload();
        }
      };
    },
    
    computed: {
      ...mapState(['isShowHeader']),
      ...mapMutations(['setIsShowHeader']),
      
      expandSubmenu1PaddingRight() {
        return this.activeMenu === 'solution' ? '13rem' : '7.3rem';
      },
      
      mainPaddingTop() {
        return this.$isBigScreen ? (this.isShowHeader ? '100px' : 0) : '60px';
      },
      
      mainMinHeight() {
        return this.$isBigScreen ? 'calc(100% - 16rem - 100px)' : 'calc(100% - 16rem - 60px)';
      },
      
      navMenuItems() {
        return [
          { index: '/', label: this.$t('menu.home'), value: 'home' },
          { index: '/solution', label: this.$t('menu.solution'), value: 'solution' },
          { index: '/product', label: this.$t('menu.product'), value: 'product' },
          { index: '/about', label: this.$t('menu.about'), value: 'about' }
        ];
      },
      
      navSubmenuItems() {
        return {
          'solution': [
            { index: '/solution/commercial', label: this.$t('menu.commercial'), value: 'commercial' },
            { index: '/solution/commercial_cases', label: this.$t('menu.commercialCases'), value: 'commercial_cases' },
            { index: '/solution/household', label: this.$t('menu.household'), value: 'household' }
          ],
          'product': [
            { index: '/product/household', label: this.$t('menu.householdProduct'), value: 'household' },
            { index: '/product/commercial', label: this.$t('menu.commercialProduct'), value: 'commercial' }
          ]
        };
      },
      
      products() {
        return {
          'household': [
            { name: this.$t('menu.single'), src: require('@/assets/images/navmenu/product-household-1.png') },
            { name: this.$t('menu.three'), src: require('@/assets/images/navmenu/product-household-2.png') }
          ],
          'commercial': [
            { name: this.$t('title.sub[11]'), src: require('@/assets/images/navmenu/product-commercial-1.png') },
            { name: this.$t('title.sub[12]'), src: require('@/assets/images/navmenu/product-commercial-2.png') },
            { name: this.$t('title.sub[13]'), src: require('@/assets/images/navmenu/product-commercial-3.png') }
          ]
        };
      }
    },
    
    watch: {
      '$route.path': {
        handler(value) {
          this.activeIndex = value;
          const pathList = value.slice(1).split('/');
          if(pathList.length > 1) {
            this.selectedMenu = pathList[0];
            this.selectedSubMenu = pathList[1];
          }
          else {
            this.selectedMenu = pathList[0];
            this.selectedSubMenu = null;
          }
        },
        immediate: true
      },
      
      isShowExpandSubmenu1(value) {
        document.getElementById('expand-submenu-1').style.height = value ? '70px' : '0px';
        document.getElementById('expand-submenu-1').style.visibility = value ? 'visible' : 'hidden';
      },
      
      isShowExpandSubmenu2(value) {
        document.getElementById('expand-submenu-2').style.height = value ? '400px' : '0px';
        document.getElementById('expand-submenu-2').style.visibility = value ? 'visible' : 'hidden';
      }
    },
    
    methods: {
      handleSetLanguage(lang) {
        this.$i18n.locale = lang;
      },
      
      handleScroll(event) {
        // 滚动条距文档顶部的距离
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // 滚动条滚动的距离
        let scrollStep = scrollTop - this.oldScrollTop;
        // 更新——滚动前，滚动条距文档顶部的距离
        this.oldScrollTop = scrollTop;
        // 滚动条向上滚动，显示网页头部，向下滚动则隐藏
        if(scrollTop > 0 && Math.abs(scrollStep) > 10) {
          this.isShowExpandSubmenu1 = false;
          this.isShowExpandSubmenu2 = false;
          this.$store.commit('setIsShowHeader', { isShowHeader: scrollStep < 0 });
        }
      },
      
      handleMenuSelect(value) {
        if(['/', '/about'].includes(value)) {
          this.activeIndex = value;
          this.$router.push(value);
        }
      },
      
      handleSubmenuSelect(value) {
        if(this.activeMenu === 'solution') {
          this.$router.push('/solution/' + value);
        }
      },
      
      handleMenuMouseenter(type) {
        this.mousePosition = 'menu';
        this.activeMenu = type;
        this.isShowExpandSubmenu1 = ['solution', 'product'].includes(type);
        this.activeSubmenu = null;
        this.isShowExpandSubmenu2 = false;
      },
      
      handleMenuMouseleave(type) {
        this.mousePosition = null;
        if(['solution', 'product'].includes(type)) {
          this.isShowExpandSubmenu1 = true;
        }
        else {
          this.activeMenu = null;
          this.isShowExpandSubmenu1 = false;
        }
      },
      
      handleSubmenuMouseenter(type) {
        this.activeSubmenu = type;
        this.isShowExpandSubmenu2 = this.activeMenu === 'product';
      },
      
      handleSubmenuMouseleave(type) {
        this.isShowExpandSubmenu2 = this.activeMenu === 'product';
      },
      
      handleExpand1Mouseenter() {
        this.mousePosition = 'submenu1';
        this.isShowExpandSubmenu1 = true;
        this.isShowExpandSubmenu2 = false;
      },
      
      handleExpand1Mouseleave() {
        this.mousePosition = null;
        if(this.activeMenu === 'solution') {
          this.activeMenu = null;
          this.isShowExpandSubmenu1 = false;
          this.activeSubmenu = null;
          this.isShowExpandSubmenu2 = false;
        }
        if(this.mousePosition === 'menu') {
          this.isShowExpandSubmenu1 = true;
          this.isShowExpandSubmenu2 = false;
        }
        if(!this.isShowExpandSubmenu2) {
          this.activeMenu = null;
          this.isShowExpandSubmenu1 = false;
        }
      },
      
      handleExpand2Mouseenter() {
        this.mousePosition = 'submenu2';
        this.isShowExpandSubmenu1 = true;
        this.isShowExpandSubmenu2 = true;
      },
      
      handleExpand2Mouseleave() {
        this.mousePosition = null;
        this.activeSubmenu = null;
        this.isShowExpandSubmenu2 = false;
        if(!this.mousePosition) {
          this.isShowExpandSubmenu1 = false;
        }
      },
      
      handleProductSelect(index) {
        this.$router.push('/product/' + this.activeSubmenu + '/' + index);
        this.handleExpand2Mouseleave();
      }
    }
  }
</script>

<style lang="scss">
  html, body, #app {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: Microsoft YaHei;
    color: #333333;
  }
  
  #expand-submenu-1 {
    width: 100%;
    height: 0;
    line-height: 70px;
    background-color: #FFFFFF;
    position: fixed;
    top: 100px;
    z-index: 9999;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4rem;
    color: #909399;
    border-bottom: 1px solid #f4f4f4;
    visibility: hidden;
    transition: height 0.3s;
    
    .menu-item {
      height: 100%;
      cursor: pointer;
      font-size: 1.6rem;
    }
    
    .active-submenu {
      color: #333333;
    }
    
    .select-submenu {
      color: #333333;
      border-bottom: 3px solid #85BD7E;
    }
  }
  
  #expand-submenu-2 {
    width: 100%;
    height: 0;
    background-color: #EAEFF3;
    position: fixed;
    top: 170px;
    z-index: 9998;
    padding: 0 12%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    gap: 80px;
    border-bottom: 1px solid #f4f4f4;
    visibility: hidden;
    transition: height 0.3s;
    
    .product {
      text-align: center;
      cursor: pointer;
      
      .image {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .name {
        margin-top: 20px;
        font-size: 1.6rem;
      }
      
      &:hover {
        padding-bottom: 30px;
        transition: all 0.2s;
      }
    }
  }
  
  .show-header {
    display: none;
    transform: translateY(-100px);
    animation: fade-down 0.6s forwards;
  }
  
  .hidden-header {
    display: flex;
    transform: translateY(0);
    animation: fade-up 0.6s forwards;
  }
  
  @keyframes fade-down {
    to {
      display: flex;
      transform: translateY(0);
    }
  }
  
  @keyframes fade-up {
    to {
      display: none;
      transform: translateY(-100px);
    }
  }
  
  .header {
    width: 100%;
    padding: 0 3.6%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 1px solid #f4f4f4;
    position: fixed;
    top: 0;
    z-index: 9999;
  }
  
  #header-big-screen {
    height: 100px;
    
    .el-menu, .el-submenu__title, .is-active {
      height: 100%;
      border: none;
    }
    
    .el-menu-item {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      padding: 0 2rem;
      
      &:first-child {
        padding-left: 0;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }
    
    .active-menu {
      color: #333333;
    }
    
    .select-menu {
      color: #85BD7E;
    }
    
    #zh-en {
      display: flex;
      align-items: center;
    }
  }
  
  #header-small-screen {
    height: 60px;
    
    i {
      font-size: 25px;
    }
  }
  
  .drawer {
    height: calc(100% - 60px);
    margin-top: 60px;
  }
  
  #main {
    width: 100%;
    transition: all 0.6s;
    overflow: hidden;
  }
  
  #footer {
    width: 100%;
    padding: 4rem 0;
    color: #B1B1B1;
    background-color: #EAEAEA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    
    #icp-record {
      a {
        color: #B1B1B1;
        
        &:hover {
          color: #85BD7E;
        }
      }
    }
  }
  
  @media screen and (min-width: 1401px) {
    #footer {
      font-size: 1.6rem;
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    #footer {
      font-size: 1.6rem;
    }
  }
  
  @media screen and (max-width: 700px) {
    #footer {
      font-size: 2.8rem;
    }
  }
</style>