export default {
  URL: '',
  
  Code: {
    SUCCESS: 200,
    REQUEST_ERROR: 501,
    RESPONSE_ERROR: 502,
    RECORD_EXIST: 610
  },
  
  Language: {
    zh: '中文(简)',
    en: 'English'
  },
  
  Storage: {
    TOKEN: 'noether-token'
  }
}
