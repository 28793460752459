import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueI18n from 'vue-i18n';
import langZh from './lang/zh.js';
import langEn from './lang/en.js';

import Carousel3d from 'vue-carousel-3d';

import AOS from 'aos';
import 'aos/dist/aos.css';

import App from './App.vue';
import store from './store';
import router from './router';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    zh: langZh,
    en: langEn
  },
  silentTranslationWarn: true, // 去除国际化警告
});
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });

Vue.use(Carousel3d);

AOS.init({ delay: 400, duration: 1000, once: true });

Vue.config.productionTip = false;

Vue.prototype.$screenWidth = window.innerWidth;

Vue.prototype.$isBigScreen = window.innerWidth > 1400;

Vue.prototype.$calcHeight = function(height) {
  return window.innerWidth / 1920 * height + 'px';
};

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
