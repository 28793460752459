<template>
  <div>
    <nav-menu :activeIndex="activeIndex" :menuItems="menuItems" @select="handleSelect" />
    
    <div style="margin-top: 60px;">
      <overview v-if="activeIndex === 0" />
      <technical v-else />
    </div>
  </div>
</template>

<script>
  import NavMenu from '@/components/NavMenu.vue';
  import Overview from '@/views/product/household/single/Overview.vue';
  import Technical from '@/views/product/household/single/Technical.vue';
  
  export default {
    
    name: 'SinglePhase',
    
    components: {
      'nav-menu': NavMenu,
      'overview': Overview,
      'technical': Technical
    },
    
    data() {
      return {
        activeIndex: 0,
        menuItems: [this.$t('menu.overview'), this.$t('menu.specification')]
      }
    },
    
    methods: {
      handleSelect(value) {
        this.activeIndex = value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>