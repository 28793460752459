<template>
  <div id="page">
    <div id="title">{{ $t('title[13]') }}</div>
    <div id="name">{{ $t('title.sub[30]') }}</div>
    <div id="devices">
      <img src="@/assets/images/product/household/three/technical1.png" alt="technical1" width="20%" height="auto">
      <img src="@/assets/images/product/household/three/technical2.png" alt="technical2" width="20%" height="auto">
    </div>
    <div id="table">
      <!-- DC input (PV) -->
      <table-tr :data="[$t('table.th[6]')]" />
      <table-tr :data="[$t('table.td[131]'), '7500Wp (6000Wp/6000Wp)', '15000Wp (7500Wp/7500Wp)']" />
      <table-tr :data="[$t('table.td[107]'), '1000V']" />
      <table-tr :data="[$t('table.td[132]'), '200V']" />
      <table-tr :data="[$t('table.td[108]'), '600V']" />
      <table-tr :data="[$t('table.td[109]'), '180V~960V']" />
      <table-tr :data="[$t('table.td[110]'), '2']" />
      <table-tr :data="[$t('table.td[133]'), '1/1', '2/2']" />
      <table-tr :data="[$t('table.td[111]'), '12.5A/12.5A', '25A/25A']" />
      <table-tr :data="[$t('table.td[134]'), '15A/15A', '30A/30A']" />
      <!-- AC output (on-grid) -->
      <table-tr :data="[$t('table.th[7]')]" />
      <table-tr :data="[$t('table.td[115]'), '5000W', '10000W']" />
      <table-tr :data="[$t('table.td[135]'), '7.2A', '14.5A']" />
      <table-tr :data="[$t('table.td[8]'), '3/N/PE, 230/400Vac']" />
      <table-tr :data="[$t('table.td[9]'), '50/60Hz']" />
      <table-tr :data="[$t('table.td[136]'), '5500VA', '11000VA']" />
      <table-tr :data="[$t('table.td[137]'), '8A', '16A']" />
      <table-tr :data="[$t('table.td[138]'), '<3%']" />
      <table-tr :data="[$t('table.td[139]'), $t('table.td[141]')]" />
      <!-- Battery parameters -->
      <table-tr :data="[$t('table.th[8]')]" />
      <table-tr :data="[$t('table.td[127]'), $t('table.td[140]') + '[3]']" />
      <table-tr :data="[$t('table.td[142]'), '180V~800V']" />
      <table-tr :data="[$t('table.td[143]'), '1', '2']" />
      <table-tr :data="[$t('table.td[144]'), '5000W', '10000W']" />
      <table-tr :data="[$t('table.td[105]'), '25A', '50a(25A/25A)']" />
      <table-tr :data="[$t('table.td[145]'), 'CAN, RS485']" />
      <!-- AC output (off-grid) -->
      <table-tr :data="[$t('table.th[9]')]" />
      <table-tr :data="[$t('table.td[115]'), '5000W', '10000W']" />
      <table-tr :data="[$t('table.td[135]'), '7.2A', '14.5A']" />
      <table-tr :data="[$t('table.td[43]'), '3/N/PE, 230/400Vac']" />
      <table-tr :data="[$t('table.td[146]'), '50/60Hz']" />
      <table-tr :data="[$t('table.td[136]'), '5500VA', '11000VA']" />
      <table-tr :data="[$t('table.td[147]'), '10000VA, 60s', '20000VA, 60s']" />
      <table-tr :data="[$t('table.td[137]'), '8A', '16A']" />
      <table-tr :data="[$t('table.td[148]'), '<3%']" />
      <table-tr :data="[$t('table.td[121]'), '<10ms']" />
      <!-- Efficency -->
      <table-tr :data="[$t('table.th[10]')]" />
      <table-tr :data="[$t('table.td[149]'), '99.9%']" />
      <table-tr :data="[$t('table.td[122]'), '98%', '98.2%']" />
      <table-tr :data="[$t('table.td[123]'), '97.5%', '97.7%']" />
      <table-tr :data="[$t('table.td[150]') + ' [1]', '97.6%', '97.8%']" />
      <!-- Protection -->
      <table-tr :data="[$t('table.th[11]')]" />
      <table-tr :data="[$t('table.td[151]'), $t('table.td[152]')]" />
      <table-tr :data="[$t('table.td[153]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[154]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[155]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[156]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[157]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[158]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[159]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[160]'), $t('table.td[151]')]" />
      <table-tr :data="[$t('table.td[161]'), 'PV: Type II, AC: Type II']" />
      <!-- General parameters -->
      <table-tr :data="[$t('table.th[12]')]" />
      <table-tr :data="[$t('table.td[162]'), '-30℃ ~ 60℃']" />
      <table-tr :data="[$t('table.td[163]'), '5%~95%']" />
      <table-tr :data="[$t('table.td[26]'), '<4000m']" />
      <table-tr :data="[$t('table.td[164]') + ' [2]', '<20W']" />
      <table-tr :data="[$t('table.td[124]'), $t('table.td[125]')]" />
      <table-tr :data="[$t('table.td[87]'), $t('table.td[165]')]" />
      <table-tr :data="[$t('table.td[53]'), 'IP65']" />
      <table-tr :data="[$t('table.td[82]'), '586.6*261.2*515mm']" />
      <table-tr :data="[$t('table.td[18]'), $t('table.td[166]'), $t('table.td[167]')]" />
      <table-tr :data="[$t('table.td[55]'), '33kg', '37kg']" />
      <table-tr :data="[$t('table.td[168]'), $t('table.td[38]')]" />
      <table-tr :data="[$t('table.td[99]'), 'LCD & Bluetooth+APP']" />
      <!-- Standard -->
      <table-tr :data="[$t('table.th[5]')]" text="EN61000-1, EN61000-2, EN61000-3, EN61000-4, EN61000-4-16, EN61000-4-18, EN61000-4-29, IEC62109-1, IEC62109-2, NB-T32004/IEC62040-1, AS/NZS 4777, VDE V 0124-100, V0126-1-1, VDE-AR-N 4105, CEI 0-21/CEI 0-16, C10/11, EN50438/EN50549, G83/G59/G98/G99, UTE C15-712-1, UNE206 007-1" />
      <table-tr :data="['【1】' + $t('text[50]'), '【2】' + $t('text[51]'), '【3】' + $t('text[48]'), '*' + $t('text[49]')]" />
    </div>
  </div>
</template>

<script>
  import TableTr from '@/components/TableTr.vue';
  
  export default {
    
    name: 'ThreeTechnical',
    
    components: {
      'table-tr': TableTr
    },
    
    data() {
      return {
        
      }
    }
  }
</script>

<style lang="scss" scoped>
  #page {
    margin: 0 auto;
    
    #title {
      font-size: 4rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding: 6rem 0;
    }
    
    #name {
      font-size: 3rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    
    #devices {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 6rem;
      margin-right: 15%;
    }
    
    #table {
      width: 100%;
      color: #333333;
      margin: 4rem 0 8.5rem 0;
    }
  }
  
  @media screen and (min-width: 1401px) {
    #page {
      width: 70%;
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    #page {
      width: 80%;
    }
  }
  
  @media screen and (max-width: 700px) {
    #page {
      width: 90%;
    }
  }
</style>