<template>
  <div>
    <section id="section1" data-aos="zoom-out" :style="{ height: $calcHeight(760) }">
      <div id="content">
        <div class="title" data-aos="fade-up" data-aos-duration="800">{{ $t('menu.household') }}</div>
        <div class="sub-title" data-aos="fade-up" data-aos-duration="800">{{ $t('title.sub[31]') }}</div>
      </div>
    </section>
    <section id="section2">
      <div class="title" data-aos="fade-right">{{ $t('menu.solution') }}</div>
      <div class="detail" data-aos="fade-left">{{ $t('text[2]') }}</div>
    </section>
    <section id="section3">
      <div class="title" data-aos="fade-down">{{ $t('title[6]') }}</div>
      <div id="content">
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/household/icon-1.png" alt="icon-1" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[2]') }}</div>
          <div class="detail">
            <div>{{ $t('text[3]') }}</div>
            <div>{{ $t('text[4]') }}</div>
          </div>
        </div>
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/household/icon-2.png" alt="icon-2" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[3]') }}</div>
          <div class="detail">
            <div>{{ $t('text[5]') }}</div>
            <div>{{ $t('text[6]') }}</div>
          </div>
        </div>
        <div class="module" data-aos="fade-up">
          <div class="image">
            <img src="@/assets/icons/solution/household/icon-3.png" alt="icon-3" width="100%" height="100%" />
          </div>
          <div class="sub-title">{{ $t('title.sub[4]') }}</div>
          <div class="detail">{{ $t('text[7]') }}</div>
        </div>
      </div>
    </section>
    <section id="section4">
      <div class="title" data-aos="fade-up">{{ $t('title[7]') }}</div>
      <div id="content">
        <div class="module" data-aos="fade-up" @click="handleClick(0)">
          <div class="title">{{ $t('title.sub[5]') }}</div>
          <div class="tip">{{ $t('tip[1]') }}</div>
          <div class="image">
            <img src="@/assets/images/solution/household/product-1.png" alt="product-1" width="100%" height="auto" />
          </div>
        </div>
        <div class="module" data-aos="fade-up" @click="handleClick(1)">
          <div class="title">{{ $t('title.sub[6]') }}</div>
          <div class="tip">{{ $t('tip[1]') }}</div>
          <div class="image">
            <img src="@/assets/images/solution/household/product-2.png" alt="product-2" width="100%" height="auto" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    
    name: 'Household',
    
    data() {
      return {
        
      }
    },
    
    methods: {
      handleClick(type) {
        this.$router.push('/product/household/' + type);
      }
    }
  }
</script>

<style lang="scss" scoped>
  section {
    box-sizing: border-box;
    
    .title {
      font-size: 4rem;
      font-weight: bold;
      white-space: nowrap;
    }
    
    .sub-title {
      font-size: 3rem;
      font-weight: bold;
    }
  }
  
  #section1 {
    width: 100%;
    background-image: url('../../assets/images/solution/household/section-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: #FFFFFF;
    position: relative;
    
    #content {
      position: absolute;
      top: 20%;
      
      .sub-title {
        margin-top: 2rem;
      }
    }
  }
  
  #section2 {
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
  }
  
  #section3 {
    width: 100%;
    background-image: url('../../assets/images/solution/household/section-3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    
    .title {
      color: #FFFFFF;
    }
    
    #content {
      display: grid;
      justify-content: space-between;
      
      .module {
        background-color: #FFFFFF;
        border-radius: 10px;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        &:hover {
          transform: translateY(-1rem);
          transition: transform 0.4s;
          outline: 2px solid #3fb74f;
          box-shadow: 0 2rem 2.7rem rgba(123,143,194,0.4);
        }
        
        .image {
          width: 6.2rem;
          height: 6.2rem;
        }
      }
    }
  }
  
  #section4 {
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7rem;
    box-sizing: border-box;
    
    #content {
      display: flex;
      gap: 5rem;
      
      .module {
        background-color: #F3F4F6;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 15px;
        
        &:hover {
          outline: 2px solid #3fb74f;
        }
        
        .tip {
          color: #85BD7E;
        }
        
        .image {
          width: 50%;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          
          &:hover {
            transform: scale(1.1);
            transition: all 0.6s;
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 1401px) {
    section {
      padding: 6rem 12%;
      
      .detail {
        font-size: 1.6rem;
        line-height: 3rem;
      }
    }
    
    #section2 {
      justify-content: space-between;
      align-items: center;
      gap: 28%;
    }
    
    #section3 {
      gap: 6rem;
      
      #content {
        grid-template-columns: repeat(3, 1fr);
        gap: 2.5rem;
        
        .module {
          width: 100%;
          padding: 3.5rem 2rem;
          
          .sub-title {
            margin-top: 2rem;
          }
          
          .detail {
            margin-top: 2rem;
          }
        }
      }
    }
    
    #section4 {
      #content {
        justify-content: space-between;
        
        .module {
          width: 50%;
          gap: 2rem;
          padding: 3rem 0;
          
          .tip {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 701px) and (max-width: 1400px) {
    section {
      padding: 6rem 8%;
      
      .title {
        font-size: 2.4rem;
      }
      
      .sub-title {
        font-size: 1.8rem;
      }
      
      .detail {
        font-size: 1.6rem;
        line-height: 3rem;
      }
    }
    
    #section2 {
      justify-content: space-between;
      align-items: center;
      gap: 28%;
    }
    
    #section3 {
      gap: 2.5rem;
      
      #content {
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
        
        .module {
          width: 100%;
          min-height: 24rem;
          padding: 3rem 2rem;
          box-sizing: border-box;
          
          .sub-title {
            font-size: 2.2rem;
            margin-top: 1rem;
          }
          
          .detail {
            font-size: 1.6rem;
            line-height: 2.5rem;
            margin-top: 1rem;
          }
        }
      }
    }
    
    #section4 {
      gap: 4rem;
      
      #content {
        justify-content: space-between;
        gap: 2rem;
        
        .module {
          width: 50%;
          gap: 1rem;
          padding: 3rem 0;
          
          .tip {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    section {
      padding: 6rem 4%;
      
      .detail {
        font-size: 3.2rem;
        line-height: 5rem;
      }
    }
    
    #section2 {
      flex-direction: column;
      justify-content: flex-start;
      gap: 3rem;
    }
    
    #section3 {
      gap: 4rem;
      
      #content {
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
        
        .module {
          width: 100%;
          min-height: 32rem;
          padding: 3rem 2rem;
          box-sizing: border-box;
          
          .sub-title {
            margin-top: 1rem;
          }
          
          .detail {
            font-size: 2.7rem;
            line-height: 3.5rem;
            margin-top: 1rem;
          }
        }
      }
    }
    
    #section4 {
      #content {
        flex-direction: column;
        
        .module {
          width: 100%;
          gap: 1rem;
          padding: 6rem 0;
          
          .tip {
            font-size: 3.2rem;
          }
        }
      }
    }
  }
</style>